.color-linear-hover {
    color: $color-white;
    &:hover {
        color: $color-linear;
    }
}

.ultra-widescreen {
    width: 100%;
    aspect-ratio: 21/9;
    object-fit: cover;
}

.widescreen {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
} 

.square {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
} 

.portrait {
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
} 

.long-portrait {
    width: 100%;
    aspect-ratio: 9/16;
    object-fit: cover;
} 

.youtube-embed {
    width: 100%;
    border-radius: 8px;
    aspect-ratio: 16/9;
    object-fit: cover;
} 