@charset "UTF-8";
/***
 Theme Name: Genz
 Description: Creative Personal Blog HTML Template
 Version: 2.0
 License: GNU General Public License v2 or later
 License URI: http://www.gnu.org/licenses/gpl-2.0.html
==============================
TABLE CONTENT
 Import Google Fonts
 Include Third Party CSS Library
 GENERAL
 HEADER
 Offcanvas Sidebar
 Search form
 Main header
 Mega menu
 Header layouts
 Page layouts
 Pagination
 Breadcrumb
 CATEGORY PAGES
 SINGLE POST
 Author box
 comment
 Post share social
 WP STYPE
 Custom amine
***/
@import url("https://fonts.googleapis.com/css?family=Share Tech");
@import url("https://fonts.googleapis.com/css?family=Share Tech Mono");
@import '../../../../node_modules/boxicons/css/boxicons.css';
/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(../css/vendors/normalize.css);
@import url(../css/vendors/uicons-regular-rounded.css);
@import url(../../css/vendors/boxicons.css);
@import url(../css/plugins/swiper-bundle.min.css);
@import url(../css/plugins/animate.min.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 148, 169, 201;
  --bs-body-bg-rgb: 8, 13, 24;
  --bs-font-sans-serif: "Share Tech", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: "Share Tech Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-monospace);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #94A9C9;
  --bs-body-bg: #080d18;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #080d18;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xsm, .container-xssm, .container-xxsm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-smd, .container-sm, .container-xsm, .container-xssm, .container-xxsm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-smd, .container-sm, .container-xsm, .container-xssm, .container-xxsm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-smd, .container-sm, .container-xsm, .container-xssm, .container-xxsm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-smd, .container-sm, .container-xsm, .container-xssm, .container-xxsm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 350px) {
  .col-xxsm {
    flex: 1 0 0%;
  }
  .row-cols-xxsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxsm-0 {
    margin-left: 0;
  }
  .offset-xxsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxsm-3 {
    margin-left: 25%;
  }
  .offset-xxsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxsm-6 {
    margin-left: 50%;
  }
  .offset-xxsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxsm-9 {
    margin-left: 75%;
  }
  .offset-xxsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xxsm-0,
  .gx-xxsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xxsm-0,
  .gy-xxsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xxsm-1,
  .gx-xxsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxsm-1,
  .gy-xxsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxsm-2,
  .gx-xxsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxsm-2,
  .gy-xxsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxsm-3,
  .gx-xxsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxsm-3,
  .gy-xxsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxsm-4,
  .gx-xxsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxsm-4,
  .gy-xxsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxsm-5,
  .gx-xxsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxsm-5,
  .gy-xxsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 450px) {
  .col-xssm {
    flex: 1 0 0%;
  }
  .row-cols-xssm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xssm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xssm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xssm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xssm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xssm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xssm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xssm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xssm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xssm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xssm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xssm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xssm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xssm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xssm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xssm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xssm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xssm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xssm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xssm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xssm-0 {
    margin-left: 0;
  }
  .offset-xssm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xssm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xssm-3 {
    margin-left: 25%;
  }
  .offset-xssm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xssm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xssm-6 {
    margin-left: 50%;
  }
  .offset-xssm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xssm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xssm-9 {
    margin-left: 75%;
  }
  .offset-xssm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xssm-11 {
    margin-left: 91.66666667%;
  }
  .g-xssm-0,
  .gx-xssm-0 {
    --bs-gutter-x: 0;
  }
  .g-xssm-0,
  .gy-xssm-0 {
    --bs-gutter-y: 0;
  }
  .g-xssm-1,
  .gx-xssm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xssm-1,
  .gy-xssm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xssm-2,
  .gx-xssm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xssm-2,
  .gy-xssm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xssm-3,
  .gx-xssm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xssm-3,
  .gy-xssm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xssm-4,
  .gx-xssm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xssm-4,
  .gy-xssm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xssm-5,
  .gx-xssm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xssm-5,
  .gy-xssm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 500px) {
  .col-xsm {
    flex: 1 0 0%;
  }
  .row-cols-xsm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xsm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xsm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xsm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xsm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xsm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xsm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xsm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xsm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xsm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xsm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xsm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xsm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xsm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xsm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xsm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xsm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xsm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xsm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xsm-0 {
    margin-left: 0;
  }
  .offset-xsm-1 {
    margin-left: 8.33333333%;
  }
  .offset-xsm-2 {
    margin-left: 16.66666667%;
  }
  .offset-xsm-3 {
    margin-left: 25%;
  }
  .offset-xsm-4 {
    margin-left: 33.33333333%;
  }
  .offset-xsm-5 {
    margin-left: 41.66666667%;
  }
  .offset-xsm-6 {
    margin-left: 50%;
  }
  .offset-xsm-7 {
    margin-left: 58.33333333%;
  }
  .offset-xsm-8 {
    margin-left: 66.66666667%;
  }
  .offset-xsm-9 {
    margin-left: 75%;
  }
  .offset-xsm-10 {
    margin-left: 83.33333333%;
  }
  .offset-xsm-11 {
    margin-left: 91.66666667%;
  }
  .g-xsm-0,
  .gx-xsm-0 {
    --bs-gutter-x: 0;
  }
  .g-xsm-0,
  .gy-xsm-0 {
    --bs-gutter-y: 0;
  }
  .g-xsm-1,
  .gx-xsm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xsm-1,
  .gy-xsm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xsm-2,
  .gx-xsm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xsm-2,
  .gy-xsm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xsm-3,
  .gx-xsm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xsm-3,
  .gy-xsm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xsm-4,
  .gx-xsm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xsm-4,
  .gy-xsm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xsm-5,
  .gx-xsm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xsm-5,
  .gy-xsm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 650px) {
  .col-smd {
    flex: 1 0 0%;
  }
  .row-cols-smd-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-smd-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-smd-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-smd-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-smd-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-smd-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-smd-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-smd-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-smd-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-smd-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-smd-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-smd-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-smd-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-smd-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-smd-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-smd-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-smd-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-smd-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-smd-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-smd-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-smd-0 {
    margin-left: 0;
  }
  .offset-smd-1 {
    margin-left: 8.33333333%;
  }
  .offset-smd-2 {
    margin-left: 16.66666667%;
  }
  .offset-smd-3 {
    margin-left: 25%;
  }
  .offset-smd-4 {
    margin-left: 33.33333333%;
  }
  .offset-smd-5 {
    margin-left: 41.66666667%;
  }
  .offset-smd-6 {
    margin-left: 50%;
  }
  .offset-smd-7 {
    margin-left: 58.33333333%;
  }
  .offset-smd-8 {
    margin-left: 66.66666667%;
  }
  .offset-smd-9 {
    margin-left: 75%;
  }
  .offset-smd-10 {
    margin-left: 83.33333333%;
  }
  .offset-smd-11 {
    margin-left: 91.66666667%;
  }
  .g-smd-0,
  .gx-smd-0 {
    --bs-gutter-x: 0;
  }
  .g-smd-0,
  .gy-smd-0 {
    --bs-gutter-y: 0;
  }
  .g-smd-1,
  .gx-smd-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-smd-1,
  .gy-smd-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-smd-2,
  .gx-smd-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-smd-2,
  .gy-smd-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-smd-3,
  .gx-smd-3 {
    --bs-gutter-x: 1rem;
  }
  .g-smd-3,
  .gy-smd-3 {
    --bs-gutter-y: 1rem;
  }
  .g-smd-4,
  .gx-smd-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-smd-4,
  .gy-smd-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-smd-5,
  .gx-smd-5 {
    --bs-gutter-x: 3rem;
  }
  .g-smd-5,
  .gy-smd-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 349.98px) {
  .table-responsive-xxsm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 449.98px) {
  .table-responsive-xssm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 499.98px) {
  .table-responsive-xsm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 649.98px) {
  .table-responsive-smd {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94A9C9;
  background-color: #080d18;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #94A9C9;
  background-color: #080d18;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #94A9C9;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #94A9C9;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94A9C9;
  background-color: #080d18;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #94A9C9;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #080d18;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #080d18, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #080d18, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #94A9C9;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #94A9C9;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #94A9C9;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 350px) {
  .dropdown-menu-xxsm-start {
    --bs-position: start;
  }
  .dropdown-menu-xxsm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxsm-end {
    --bs-position: end;
  }
  .dropdown-menu-xxsm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 450px) {
  .dropdown-menu-xssm-start {
    --bs-position: start;
  }
  .dropdown-menu-xssm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xssm-end {
    --bs-position: end;
  }
  .dropdown-menu-xssm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 500px) {
  .dropdown-menu-xsm-start {
    --bs-position: start;
  }
  .dropdown-menu-xsm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xsm-end {
    --bs-position: end;
  }
  .dropdown-menu-xsm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 650px) {
  .dropdown-menu-smd-start {
    --bs-position: start;
  }
  .dropdown-menu-smd-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-smd-end {
    --bs-position: end;
  }
  .dropdown-menu-smd-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #080d18;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #080d18;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 350px) {
  .navbar-expand-xxsm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxsm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxsm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxsm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxsm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxsm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxsm .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxsm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxsm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxsm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 450px) {
  .navbar-expand-xssm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xssm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xssm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xssm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xssm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xssm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xssm .navbar-toggler {
    display: none;
  }
  .navbar-expand-xssm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xssm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xssm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 500px) {
  .navbar-expand-xsm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xsm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xsm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xsm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xsm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xsm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xsm .navbar-toggler {
    display: none;
  }
  .navbar-expand-xsm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xsm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xsm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 650px) {
  .navbar-expand-smd {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-smd .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-smd .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-smd .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-smd .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-smd .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-smd .navbar-toggler {
    display: none;
  }
  .navbar-expand-smd .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-smd .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-smd .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #94A9C9;
  --bs-accordion-bg: #080d18;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 0px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #94A9C9;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2394A9C9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #94A9C9;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 350px) {
  .list-group-horizontal-xxsm {
    flex-direction: row;
  }
  .list-group-horizontal-xxsm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxsm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxsm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxsm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxsm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 450px) {
  .list-group-horizontal-xssm {
    flex-direction: row;
  }
  .list-group-horizontal-xssm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xssm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xssm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xssm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xssm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 500px) {
  .list-group-horizontal-xsm {
    flex-direction: row;
  }
  .list-group-horizontal-xsm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xsm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xsm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xsm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xsm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 650px) {
  .list-group-horizontal-smd {
    flex-direction: row;
  }
  .list-group-horizontal-smd > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-smd > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-smd > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-smd > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-smd > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 349.98px) {
  .modal-fullscreen-xxsm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxsm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxsm-down .modal-header,
  .modal-fullscreen-xxsm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxsm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 449.98px) {
  .modal-fullscreen-xssm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xssm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xssm-down .modal-header,
  .modal-fullscreen-xssm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xssm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 499.98px) {
  .modal-fullscreen-xsm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xsm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xsm-down .modal-header,
  .modal-fullscreen-xsm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xsm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 649.98px) {
  .modal-fullscreen-smd-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-smd-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-smd-down .modal-header,
  .modal-fullscreen-smd-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-smd-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-monospace);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #94A9C9;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-monospace);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-smd, .offcanvas-sm, .offcanvas-xsm, .offcanvas-xssm, .offcanvas-xxsm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 349.98px) {
  .offcanvas-xxsm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 349.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxsm {
    transition: none;
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.showing, .offcanvas-xxsm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 349.98px) {
  .offcanvas-xxsm.showing, .offcanvas-xxsm.hiding, .offcanvas-xxsm.show {
    visibility: visible;
  }
}
@media (min-width: 350px) {
  .offcanvas-xxsm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxsm .offcanvas-header {
    display: none;
  }
  .offcanvas-xxsm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 449.98px) {
  .offcanvas-xssm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 449.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xssm {
    transition: none;
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.showing, .offcanvas-xssm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 449.98px) {
  .offcanvas-xssm.showing, .offcanvas-xssm.hiding, .offcanvas-xssm.show {
    visibility: visible;
  }
}
@media (min-width: 450px) {
  .offcanvas-xssm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xssm .offcanvas-header {
    display: none;
  }
  .offcanvas-xssm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 499.98px) {
  .offcanvas-xsm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 499.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xsm {
    transition: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.showing, .offcanvas-xsm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-xsm.showing, .offcanvas-xsm.hiding, .offcanvas-xsm.show {
    visibility: visible;
  }
}
@media (min-width: 500px) {
  .offcanvas-xsm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xsm .offcanvas-header {
    display: none;
  }
  .offcanvas-xsm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 649.98px) {
  .offcanvas-smd {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 649.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-smd {
    transition: none;
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.showing, .offcanvas-smd.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 649.98px) {
  .offcanvas-smd.showing, .offcanvas-smd.hiding, .offcanvas-smd.show {
    visibility: visible;
  }
}
@media (min-width: 650px) {
  .offcanvas-smd {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-smd .offcanvas-header {
    display: none;
  }
  .offcanvas-smd .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 350px) {
  .sticky-xxsm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxsm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 450px) {
  .sticky-xssm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xssm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 500px) {
  .sticky-xsm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xsm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 650px) {
  .sticky-smd-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-smd-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 350px) {
  .float-xxsm-start {
    float: left !important;
  }
  .float-xxsm-end {
    float: right !important;
  }
  .float-xxsm-none {
    float: none !important;
  }
  .d-xxsm-inline {
    display: inline !important;
  }
  .d-xxsm-inline-block {
    display: inline-block !important;
  }
  .d-xxsm-block {
    display: block !important;
  }
  .d-xxsm-grid {
    display: grid !important;
  }
  .d-xxsm-table {
    display: table !important;
  }
  .d-xxsm-table-row {
    display: table-row !important;
  }
  .d-xxsm-table-cell {
    display: table-cell !important;
  }
  .d-xxsm-flex {
    display: flex !important;
  }
  .d-xxsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xxsm-none {
    display: none !important;
  }
  .flex-xxsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxsm-row {
    flex-direction: row !important;
  }
  .flex-xxsm-column {
    flex-direction: column !important;
  }
  .flex-xxsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxsm-center {
    justify-content: center !important;
  }
  .justify-content-xxsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xxsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xxsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxsm-start {
    align-items: flex-start !important;
  }
  .align-items-xxsm-end {
    align-items: flex-end !important;
  }
  .align-items-xxsm-center {
    align-items: center !important;
  }
  .align-items-xxsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xxsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xxsm-start {
    align-content: flex-start !important;
  }
  .align-content-xxsm-end {
    align-content: flex-end !important;
  }
  .align-content-xxsm-center {
    align-content: center !important;
  }
  .align-content-xxsm-between {
    align-content: space-between !important;
  }
  .align-content-xxsm-around {
    align-content: space-around !important;
  }
  .align-content-xxsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xxsm-auto {
    align-self: auto !important;
  }
  .align-self-xxsm-start {
    align-self: flex-start !important;
  }
  .align-self-xxsm-end {
    align-self: flex-end !important;
  }
  .align-self-xxsm-center {
    align-self: center !important;
  }
  .align-self-xxsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xxsm-stretch {
    align-self: stretch !important;
  }
  .order-xxsm-first {
    order: -1 !important;
  }
  .order-xxsm-0 {
    order: 0 !important;
  }
  .order-xxsm-1 {
    order: 1 !important;
  }
  .order-xxsm-2 {
    order: 2 !important;
  }
  .order-xxsm-3 {
    order: 3 !important;
  }
  .order-xxsm-4 {
    order: 4 !important;
  }
  .order-xxsm-5 {
    order: 5 !important;
  }
  .order-xxsm-last {
    order: 6 !important;
  }
  .m-xxsm-0 {
    margin: 0 !important;
  }
  .m-xxsm-1 {
    margin: 0.25rem !important;
  }
  .m-xxsm-2 {
    margin: 0.5rem !important;
  }
  .m-xxsm-3 {
    margin: 1rem !important;
  }
  .m-xxsm-4 {
    margin: 1.5rem !important;
  }
  .m-xxsm-5 {
    margin: 3rem !important;
  }
  .m-xxsm-auto {
    margin: auto !important;
  }
  .mx-xxsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxsm-0 {
    margin-top: 0 !important;
  }
  .mt-xxsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xxsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xxsm-auto {
    margin-top: auto !important;
  }
  .me-xxsm-0 {
    margin-right: 0 !important;
  }
  .me-xxsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxsm-3 {
    margin-right: 1rem !important;
  }
  .me-xxsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxsm-5 {
    margin-right: 3rem !important;
  }
  .me-xxsm-auto {
    margin-right: auto !important;
  }
  .mb-xxsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xxsm-0 {
    margin-left: 0 !important;
  }
  .ms-xxsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xxsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xxsm-auto {
    margin-left: auto !important;
  }
  .p-xxsm-0 {
    padding: 0 !important;
  }
  .p-xxsm-1 {
    padding: 0.25rem !important;
  }
  .p-xxsm-2 {
    padding: 0.5rem !important;
  }
  .p-xxsm-3 {
    padding: 1rem !important;
  }
  .p-xxsm-4 {
    padding: 1.5rem !important;
  }
  .p-xxsm-5 {
    padding: 3rem !important;
  }
  .px-xxsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxsm-0 {
    padding-top: 0 !important;
  }
  .pt-xxsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xxsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xxsm-0 {
    padding-right: 0 !important;
  }
  .pe-xxsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xxsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xxsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxsm-0 {
    padding-left: 0 !important;
  }
  .ps-xxsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xxsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxsm-5 {
    padding-left: 3rem !important;
  }
  .gap-xxsm-0 {
    gap: 0 !important;
  }
  .gap-xxsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xxsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xxsm-3 {
    gap: 1rem !important;
  }
  .gap-xxsm-4 {
    gap: 1.5rem !important;
  }
  .gap-xxsm-5 {
    gap: 3rem !important;
  }
  .text-xxsm-start {
    text-align: left !important;
  }
  .text-xxsm-end {
    text-align: right !important;
  }
  .text-xxsm-center {
    text-align: center !important;
  }
}
@media (min-width: 450px) {
  .float-xssm-start {
    float: left !important;
  }
  .float-xssm-end {
    float: right !important;
  }
  .float-xssm-none {
    float: none !important;
  }
  .d-xssm-inline {
    display: inline !important;
  }
  .d-xssm-inline-block {
    display: inline-block !important;
  }
  .d-xssm-block {
    display: block !important;
  }
  .d-xssm-grid {
    display: grid !important;
  }
  .d-xssm-table {
    display: table !important;
  }
  .d-xssm-table-row {
    display: table-row !important;
  }
  .d-xssm-table-cell {
    display: table-cell !important;
  }
  .d-xssm-flex {
    display: flex !important;
  }
  .d-xssm-inline-flex {
    display: inline-flex !important;
  }
  .d-xssm-none {
    display: none !important;
  }
  .flex-xssm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xssm-row {
    flex-direction: row !important;
  }
  .flex-xssm-column {
    flex-direction: column !important;
  }
  .flex-xssm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xssm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xssm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xssm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xssm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xssm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xssm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xssm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xssm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xssm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xssm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xssm-center {
    justify-content: center !important;
  }
  .justify-content-xssm-between {
    justify-content: space-between !important;
  }
  .justify-content-xssm-around {
    justify-content: space-around !important;
  }
  .justify-content-xssm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xssm-start {
    align-items: flex-start !important;
  }
  .align-items-xssm-end {
    align-items: flex-end !important;
  }
  .align-items-xssm-center {
    align-items: center !important;
  }
  .align-items-xssm-baseline {
    align-items: baseline !important;
  }
  .align-items-xssm-stretch {
    align-items: stretch !important;
  }
  .align-content-xssm-start {
    align-content: flex-start !important;
  }
  .align-content-xssm-end {
    align-content: flex-end !important;
  }
  .align-content-xssm-center {
    align-content: center !important;
  }
  .align-content-xssm-between {
    align-content: space-between !important;
  }
  .align-content-xssm-around {
    align-content: space-around !important;
  }
  .align-content-xssm-stretch {
    align-content: stretch !important;
  }
  .align-self-xssm-auto {
    align-self: auto !important;
  }
  .align-self-xssm-start {
    align-self: flex-start !important;
  }
  .align-self-xssm-end {
    align-self: flex-end !important;
  }
  .align-self-xssm-center {
    align-self: center !important;
  }
  .align-self-xssm-baseline {
    align-self: baseline !important;
  }
  .align-self-xssm-stretch {
    align-self: stretch !important;
  }
  .order-xssm-first {
    order: -1 !important;
  }
  .order-xssm-0 {
    order: 0 !important;
  }
  .order-xssm-1 {
    order: 1 !important;
  }
  .order-xssm-2 {
    order: 2 !important;
  }
  .order-xssm-3 {
    order: 3 !important;
  }
  .order-xssm-4 {
    order: 4 !important;
  }
  .order-xssm-5 {
    order: 5 !important;
  }
  .order-xssm-last {
    order: 6 !important;
  }
  .m-xssm-0 {
    margin: 0 !important;
  }
  .m-xssm-1 {
    margin: 0.25rem !important;
  }
  .m-xssm-2 {
    margin: 0.5rem !important;
  }
  .m-xssm-3 {
    margin: 1rem !important;
  }
  .m-xssm-4 {
    margin: 1.5rem !important;
  }
  .m-xssm-5 {
    margin: 3rem !important;
  }
  .m-xssm-auto {
    margin: auto !important;
  }
  .mx-xssm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xssm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xssm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xssm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xssm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xssm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xssm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xssm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xssm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xssm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xssm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xssm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xssm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xssm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xssm-0 {
    margin-top: 0 !important;
  }
  .mt-xssm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xssm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xssm-3 {
    margin-top: 1rem !important;
  }
  .mt-xssm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xssm-5 {
    margin-top: 3rem !important;
  }
  .mt-xssm-auto {
    margin-top: auto !important;
  }
  .me-xssm-0 {
    margin-right: 0 !important;
  }
  .me-xssm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xssm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xssm-3 {
    margin-right: 1rem !important;
  }
  .me-xssm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xssm-5 {
    margin-right: 3rem !important;
  }
  .me-xssm-auto {
    margin-right: auto !important;
  }
  .mb-xssm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xssm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xssm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xssm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xssm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xssm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xssm-auto {
    margin-bottom: auto !important;
  }
  .ms-xssm-0 {
    margin-left: 0 !important;
  }
  .ms-xssm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xssm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xssm-3 {
    margin-left: 1rem !important;
  }
  .ms-xssm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xssm-5 {
    margin-left: 3rem !important;
  }
  .ms-xssm-auto {
    margin-left: auto !important;
  }
  .p-xssm-0 {
    padding: 0 !important;
  }
  .p-xssm-1 {
    padding: 0.25rem !important;
  }
  .p-xssm-2 {
    padding: 0.5rem !important;
  }
  .p-xssm-3 {
    padding: 1rem !important;
  }
  .p-xssm-4 {
    padding: 1.5rem !important;
  }
  .p-xssm-5 {
    padding: 3rem !important;
  }
  .px-xssm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xssm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xssm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xssm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xssm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xssm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xssm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xssm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xssm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xssm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xssm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xssm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xssm-0 {
    padding-top: 0 !important;
  }
  .pt-xssm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xssm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xssm-3 {
    padding-top: 1rem !important;
  }
  .pt-xssm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xssm-5 {
    padding-top: 3rem !important;
  }
  .pe-xssm-0 {
    padding-right: 0 !important;
  }
  .pe-xssm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xssm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xssm-3 {
    padding-right: 1rem !important;
  }
  .pe-xssm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xssm-5 {
    padding-right: 3rem !important;
  }
  .pb-xssm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xssm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xssm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xssm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xssm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xssm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xssm-0 {
    padding-left: 0 !important;
  }
  .ps-xssm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xssm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xssm-3 {
    padding-left: 1rem !important;
  }
  .ps-xssm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xssm-5 {
    padding-left: 3rem !important;
  }
  .gap-xssm-0 {
    gap: 0 !important;
  }
  .gap-xssm-1 {
    gap: 0.25rem !important;
  }
  .gap-xssm-2 {
    gap: 0.5rem !important;
  }
  .gap-xssm-3 {
    gap: 1rem !important;
  }
  .gap-xssm-4 {
    gap: 1.5rem !important;
  }
  .gap-xssm-5 {
    gap: 3rem !important;
  }
  .text-xssm-start {
    text-align: left !important;
  }
  .text-xssm-end {
    text-align: right !important;
  }
  .text-xssm-center {
    text-align: center !important;
  }
}
@media (min-width: 500px) {
  .float-xsm-start {
    float: left !important;
  }
  .float-xsm-end {
    float: right !important;
  }
  .float-xsm-none {
    float: none !important;
  }
  .d-xsm-inline {
    display: inline !important;
  }
  .d-xsm-inline-block {
    display: inline-block !important;
  }
  .d-xsm-block {
    display: block !important;
  }
  .d-xsm-grid {
    display: grid !important;
  }
  .d-xsm-table {
    display: table !important;
  }
  .d-xsm-table-row {
    display: table-row !important;
  }
  .d-xsm-table-cell {
    display: table-cell !important;
  }
  .d-xsm-flex {
    display: flex !important;
  }
  .d-xsm-inline-flex {
    display: inline-flex !important;
  }
  .d-xsm-none {
    display: none !important;
  }
  .flex-xsm-fill {
    flex: 1 1 auto !important;
  }
  .flex-xsm-row {
    flex-direction: row !important;
  }
  .flex-xsm-column {
    flex-direction: column !important;
  }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xsm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xsm-start {
    justify-content: flex-start !important;
  }
  .justify-content-xsm-end {
    justify-content: flex-end !important;
  }
  .justify-content-xsm-center {
    justify-content: center !important;
  }
  .justify-content-xsm-between {
    justify-content: space-between !important;
  }
  .justify-content-xsm-around {
    justify-content: space-around !important;
  }
  .justify-content-xsm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xsm-start {
    align-items: flex-start !important;
  }
  .align-items-xsm-end {
    align-items: flex-end !important;
  }
  .align-items-xsm-center {
    align-items: center !important;
  }
  .align-items-xsm-baseline {
    align-items: baseline !important;
  }
  .align-items-xsm-stretch {
    align-items: stretch !important;
  }
  .align-content-xsm-start {
    align-content: flex-start !important;
  }
  .align-content-xsm-end {
    align-content: flex-end !important;
  }
  .align-content-xsm-center {
    align-content: center !important;
  }
  .align-content-xsm-between {
    align-content: space-between !important;
  }
  .align-content-xsm-around {
    align-content: space-around !important;
  }
  .align-content-xsm-stretch {
    align-content: stretch !important;
  }
  .align-self-xsm-auto {
    align-self: auto !important;
  }
  .align-self-xsm-start {
    align-self: flex-start !important;
  }
  .align-self-xsm-end {
    align-self: flex-end !important;
  }
  .align-self-xsm-center {
    align-self: center !important;
  }
  .align-self-xsm-baseline {
    align-self: baseline !important;
  }
  .align-self-xsm-stretch {
    align-self: stretch !important;
  }
  .order-xsm-first {
    order: -1 !important;
  }
  .order-xsm-0 {
    order: 0 !important;
  }
  .order-xsm-1 {
    order: 1 !important;
  }
  .order-xsm-2 {
    order: 2 !important;
  }
  .order-xsm-3 {
    order: 3 !important;
  }
  .order-xsm-4 {
    order: 4 !important;
  }
  .order-xsm-5 {
    order: 5 !important;
  }
  .order-xsm-last {
    order: 6 !important;
  }
  .m-xsm-0 {
    margin: 0 !important;
  }
  .m-xsm-1 {
    margin: 0.25rem !important;
  }
  .m-xsm-2 {
    margin: 0.5rem !important;
  }
  .m-xsm-3 {
    margin: 1rem !important;
  }
  .m-xsm-4 {
    margin: 1.5rem !important;
  }
  .m-xsm-5 {
    margin: 3rem !important;
  }
  .m-xsm-auto {
    margin: auto !important;
  }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xsm-0 {
    margin-top: 0 !important;
  }
  .mt-xsm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xsm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 1rem !important;
  }
  .mt-xsm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xsm-5 {
    margin-top: 3rem !important;
  }
  .mt-xsm-auto {
    margin-top: auto !important;
  }
  .me-xsm-0 {
    margin-right: 0 !important;
  }
  .me-xsm-1 {
    margin-right: 0.25rem !important;
  }
  .me-xsm-2 {
    margin-right: 0.5rem !important;
  }
  .me-xsm-3 {
    margin-right: 1rem !important;
  }
  .me-xsm-4 {
    margin-right: 1.5rem !important;
  }
  .me-xsm-5 {
    margin-right: 3rem !important;
  }
  .me-xsm-auto {
    margin-right: auto !important;
  }
  .mb-xsm-0 {
    margin-bottom: 0 !important;
  }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xsm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xsm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xsm-auto {
    margin-bottom: auto !important;
  }
  .ms-xsm-0 {
    margin-left: 0 !important;
  }
  .ms-xsm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xsm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xsm-3 {
    margin-left: 1rem !important;
  }
  .ms-xsm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xsm-5 {
    margin-left: 3rem !important;
  }
  .ms-xsm-auto {
    margin-left: auto !important;
  }
  .p-xsm-0 {
    padding: 0 !important;
  }
  .p-xsm-1 {
    padding: 0.25rem !important;
  }
  .p-xsm-2 {
    padding: 0.5rem !important;
  }
  .p-xsm-3 {
    padding: 1rem !important;
  }
  .p-xsm-4 {
    padding: 1.5rem !important;
  }
  .p-xsm-5 {
    padding: 3rem !important;
  }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xsm-0 {
    padding-top: 0 !important;
  }
  .pt-xsm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xsm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xsm-3 {
    padding-top: 1rem !important;
  }
  .pt-xsm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xsm-5 {
    padding-top: 3rem !important;
  }
  .pe-xsm-0 {
    padding-right: 0 !important;
  }
  .pe-xsm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xsm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xsm-3 {
    padding-right: 1rem !important;
  }
  .pe-xsm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xsm-5 {
    padding-right: 3rem !important;
  }
  .pb-xsm-0 {
    padding-bottom: 0 !important;
  }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xsm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xsm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xsm-0 {
    padding-left: 0 !important;
  }
  .ps-xsm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xsm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xsm-3 {
    padding-left: 1rem !important;
  }
  .ps-xsm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xsm-5 {
    padding-left: 3rem !important;
  }
  .gap-xsm-0 {
    gap: 0 !important;
  }
  .gap-xsm-1 {
    gap: 0.25rem !important;
  }
  .gap-xsm-2 {
    gap: 0.5rem !important;
  }
  .gap-xsm-3 {
    gap: 1rem !important;
  }
  .gap-xsm-4 {
    gap: 1.5rem !important;
  }
  .gap-xsm-5 {
    gap: 3rem !important;
  }
  .text-xsm-start {
    text-align: left !important;
  }
  .text-xsm-end {
    text-align: right !important;
  }
  .text-xsm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 650px) {
  .float-smd-start {
    float: left !important;
  }
  .float-smd-end {
    float: right !important;
  }
  .float-smd-none {
    float: none !important;
  }
  .d-smd-inline {
    display: inline !important;
  }
  .d-smd-inline-block {
    display: inline-block !important;
  }
  .d-smd-block {
    display: block !important;
  }
  .d-smd-grid {
    display: grid !important;
  }
  .d-smd-table {
    display: table !important;
  }
  .d-smd-table-row {
    display: table-row !important;
  }
  .d-smd-table-cell {
    display: table-cell !important;
  }
  .d-smd-flex {
    display: flex !important;
  }
  .d-smd-inline-flex {
    display: inline-flex !important;
  }
  .d-smd-none {
    display: none !important;
  }
  .flex-smd-fill {
    flex: 1 1 auto !important;
  }
  .flex-smd-row {
    flex-direction: row !important;
  }
  .flex-smd-column {
    flex-direction: column !important;
  }
  .flex-smd-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-smd-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-smd-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-smd-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-smd-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-smd-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-smd-wrap {
    flex-wrap: wrap !important;
  }
  .flex-smd-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-smd-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-smd-start {
    justify-content: flex-start !important;
  }
  .justify-content-smd-end {
    justify-content: flex-end !important;
  }
  .justify-content-smd-center {
    justify-content: center !important;
  }
  .justify-content-smd-between {
    justify-content: space-between !important;
  }
  .justify-content-smd-around {
    justify-content: space-around !important;
  }
  .justify-content-smd-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-smd-start {
    align-items: flex-start !important;
  }
  .align-items-smd-end {
    align-items: flex-end !important;
  }
  .align-items-smd-center {
    align-items: center !important;
  }
  .align-items-smd-baseline {
    align-items: baseline !important;
  }
  .align-items-smd-stretch {
    align-items: stretch !important;
  }
  .align-content-smd-start {
    align-content: flex-start !important;
  }
  .align-content-smd-end {
    align-content: flex-end !important;
  }
  .align-content-smd-center {
    align-content: center !important;
  }
  .align-content-smd-between {
    align-content: space-between !important;
  }
  .align-content-smd-around {
    align-content: space-around !important;
  }
  .align-content-smd-stretch {
    align-content: stretch !important;
  }
  .align-self-smd-auto {
    align-self: auto !important;
  }
  .align-self-smd-start {
    align-self: flex-start !important;
  }
  .align-self-smd-end {
    align-self: flex-end !important;
  }
  .align-self-smd-center {
    align-self: center !important;
  }
  .align-self-smd-baseline {
    align-self: baseline !important;
  }
  .align-self-smd-stretch {
    align-self: stretch !important;
  }
  .order-smd-first {
    order: -1 !important;
  }
  .order-smd-0 {
    order: 0 !important;
  }
  .order-smd-1 {
    order: 1 !important;
  }
  .order-smd-2 {
    order: 2 !important;
  }
  .order-smd-3 {
    order: 3 !important;
  }
  .order-smd-4 {
    order: 4 !important;
  }
  .order-smd-5 {
    order: 5 !important;
  }
  .order-smd-last {
    order: 6 !important;
  }
  .m-smd-0 {
    margin: 0 !important;
  }
  .m-smd-1 {
    margin: 0.25rem !important;
  }
  .m-smd-2 {
    margin: 0.5rem !important;
  }
  .m-smd-3 {
    margin: 1rem !important;
  }
  .m-smd-4 {
    margin: 1.5rem !important;
  }
  .m-smd-5 {
    margin: 3rem !important;
  }
  .m-smd-auto {
    margin: auto !important;
  }
  .mx-smd-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-smd-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-smd-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-smd-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-smd-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-smd-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-smd-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-smd-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-smd-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-smd-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-smd-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-smd-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-smd-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-smd-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-smd-0 {
    margin-top: 0 !important;
  }
  .mt-smd-1 {
    margin-top: 0.25rem !important;
  }
  .mt-smd-2 {
    margin-top: 0.5rem !important;
  }
  .mt-smd-3 {
    margin-top: 1rem !important;
  }
  .mt-smd-4 {
    margin-top: 1.5rem !important;
  }
  .mt-smd-5 {
    margin-top: 3rem !important;
  }
  .mt-smd-auto {
    margin-top: auto !important;
  }
  .me-smd-0 {
    margin-right: 0 !important;
  }
  .me-smd-1 {
    margin-right: 0.25rem !important;
  }
  .me-smd-2 {
    margin-right: 0.5rem !important;
  }
  .me-smd-3 {
    margin-right: 1rem !important;
  }
  .me-smd-4 {
    margin-right: 1.5rem !important;
  }
  .me-smd-5 {
    margin-right: 3rem !important;
  }
  .me-smd-auto {
    margin-right: auto !important;
  }
  .mb-smd-0 {
    margin-bottom: 0 !important;
  }
  .mb-smd-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-smd-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-smd-3 {
    margin-bottom: 1rem !important;
  }
  .mb-smd-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-smd-5 {
    margin-bottom: 3rem !important;
  }
  .mb-smd-auto {
    margin-bottom: auto !important;
  }
  .ms-smd-0 {
    margin-left: 0 !important;
  }
  .ms-smd-1 {
    margin-left: 0.25rem !important;
  }
  .ms-smd-2 {
    margin-left: 0.5rem !important;
  }
  .ms-smd-3 {
    margin-left: 1rem !important;
  }
  .ms-smd-4 {
    margin-left: 1.5rem !important;
  }
  .ms-smd-5 {
    margin-left: 3rem !important;
  }
  .ms-smd-auto {
    margin-left: auto !important;
  }
  .p-smd-0 {
    padding: 0 !important;
  }
  .p-smd-1 {
    padding: 0.25rem !important;
  }
  .p-smd-2 {
    padding: 0.5rem !important;
  }
  .p-smd-3 {
    padding: 1rem !important;
  }
  .p-smd-4 {
    padding: 1.5rem !important;
  }
  .p-smd-5 {
    padding: 3rem !important;
  }
  .px-smd-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-smd-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-smd-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-smd-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-smd-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-smd-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-smd-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-smd-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-smd-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-smd-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-smd-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-smd-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-smd-0 {
    padding-top: 0 !important;
  }
  .pt-smd-1 {
    padding-top: 0.25rem !important;
  }
  .pt-smd-2 {
    padding-top: 0.5rem !important;
  }
  .pt-smd-3 {
    padding-top: 1rem !important;
  }
  .pt-smd-4 {
    padding-top: 1.5rem !important;
  }
  .pt-smd-5 {
    padding-top: 3rem !important;
  }
  .pe-smd-0 {
    padding-right: 0 !important;
  }
  .pe-smd-1 {
    padding-right: 0.25rem !important;
  }
  .pe-smd-2 {
    padding-right: 0.5rem !important;
  }
  .pe-smd-3 {
    padding-right: 1rem !important;
  }
  .pe-smd-4 {
    padding-right: 1.5rem !important;
  }
  .pe-smd-5 {
    padding-right: 3rem !important;
  }
  .pb-smd-0 {
    padding-bottom: 0 !important;
  }
  .pb-smd-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-smd-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-smd-3 {
    padding-bottom: 1rem !important;
  }
  .pb-smd-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-smd-5 {
    padding-bottom: 3rem !important;
  }
  .ps-smd-0 {
    padding-left: 0 !important;
  }
  .ps-smd-1 {
    padding-left: 0.25rem !important;
  }
  .ps-smd-2 {
    padding-left: 0.5rem !important;
  }
  .ps-smd-3 {
    padding-left: 1rem !important;
  }
  .ps-smd-4 {
    padding-left: 1.5rem !important;
  }
  .ps-smd-5 {
    padding-left: 3rem !important;
  }
  .gap-smd-0 {
    gap: 0 !important;
  }
  .gap-smd-1 {
    gap: 0.25rem !important;
  }
  .gap-smd-2 {
    gap: 0.5rem !important;
  }
  .gap-smd-3 {
    gap: 1rem !important;
  }
  .gap-smd-4 {
    gap: 1.5rem !important;
  }
  .gap-smd-5 {
    gap: 3rem !important;
  }
  .text-smd-start {
    text-align: left !important;
  }
  .text-smd-end {
    text-align: right !important;
  }
  .text-smd-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*import vendors*/ /*RESET*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
.small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
.mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

main {
  display: block;
  clear: both;
}

thead {
  font-weight: 600;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

input,
select,
button,
textarea {
  font-family: var(--bs-font-monospace);
  font-size: 0.875em;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

li.hr span {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  margin: 20px 0;
  display: block;
}

/*--- Common Classes---------------------*/
::placeholder {
  color: #B8CDEB;
  opacity: 0.5;
}

:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: #B8CDEB;
  opacity: 0.5;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

.height-100vh {
  height: 100vh !important;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.border-radius {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-20 {
  border-radius: 20px;
}

.hover-up {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.hover-up:hover {
  transform: translateY(-2px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.hover-shadow {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.hover-shadow:hover {
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
}

.card-hover-border {
  border: 1px solid #222F43;
}
.card-hover-border:hover {
  background: linear-gradient(#0d1322, #0d1322) padding-box, linear-gradient(to right, rgb(14, 165, 234), rgb(11, 209, 209)) border-box;
  border-radius: 16px;
  border: 1px solid transparent;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #000000;
}

*:hover {
  transition-duration: 0.2s;
}

.img-responsive {
  max-width: 100%;
}

body {
  font-family: var(--bs-font-monospace);
}

.text-head-2 {
  font-size: 42px;
  line-height: 44px;
  font-weight: bold;
  font-family: var(--bs-font-monospace);
}

.text-heading-4 {
  color: #ffffff;
  font-size: 28px;
  line-height: 32px;
  font-family: var(--bs-font-monospace);
}

.text-base {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
}

.text-3xl {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.text-2xl {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.text-xl {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.text-lg {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.text-md {
  font-family: var(--bs-font-monospace);
  font-size: 0.9375em !important;
  line-height: 22px !important;
  font-weight: 400;
}

.text-sm {
  font-family: var(--bs-font-monospace);
  font-size: 0.875em !important;
  line-height: 20px !important;
  font-weight: 400;
}

.text-sm-bold {
  font-family: var(--bs-font-monospace);
  font-size: 0.875em !important;
  line-height: 20px !important;
  font-weight: 700;
}

.text-xs {
  font-family: var(--bs-font-monospace);
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
}

.bold {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
}

h1, .h1 {
  font-family: var(--bs-font-monospace);
  font-weight: 800;
  font-size: 5em;
  line-height: 2.5em;
  color: #000000;
}

h2, .h2 {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
  font-size: 3.125em;
  line-height: 1.5625em;
  color: #000000;
}

h3, .h3 {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1.25em;
  color: #000000;
}

h4, .h4, .heading-4 {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
  font-size: 1.5em;
  line-height: 33px;
  color: #000000;
}

h5, .h5 {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #000000;
}

h6, .h6 {
  font-family: var(--bs-font-monospace);
  font-weight: 700;
  font-size: 1.125em;
  line-height: 22px;
  color: #000000;
}

.color-gray-50 {
  color: #E6F0FF;
}

.color-gray-100 {
  color: #D8E6FB;
}

.color-gray-200 {
  color: #C9DAF5;
}

.color-gray-300 {
  color: #B8CDEB;
}

.color-gray-400 {
  color: #A7BDDD;
}

.color-gray-500 {
  color: #94A9C9;
}

.color-gray-600 {
  color: #7F92B0;
}

.color-gray-700 {
  color: #66768F;
}

.color-gray-800 {
  color: #222F43;
}

.color-gray-850 {
  color: #0d1322 !important;
}

.color-gray-900 {
  color: #080d18 !important;
}

.color-gray-950 {
  color: #05080f !important;
}

.bg-gray-50 {
  background-color: #E6F0FF;
}

.bg-gray-100 {
  background-color: #D8E6FB;
}

.bg-gray-200 {
  background-color: #C9DAF5;
}

.bg-gray-300 {
  background-color: #B8CDEB;
}

.bg-gray-400 {
  background-color: #A7BDDD;
}

.bg-gray-500 {
  background-color: #94A9C9;
}

.bg-gray-600 {
  background-color: #7F92B0;
}

.bg-gray-700 {
  background-color: #66768F;
}

.bg-gray-800 {
  background-color: #222F43 !important;
}

.bg-gray-850 {
  background-color: #0d1322;
}

.bg-gray-900 {
  background-color: #080d18 !important;
}

.bg-gray-950 {
  background-color: #05080f !important;
}

.color-white {
  color: #b9e0f2 !important;
}

.border-gray-800 {
  border-color: #222F43 !important;
}

.border-gray-850 {
  border-color: #0d1322 !important;
}

.border-gray-500 {
  border-color: #94A9C9 !important;
}

.color-linear {
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent;
}

.color-white {
  color: #b9e0f2;
}

.form-control {
  border-radius: 8px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  color: #7F92B0;
}

.form-control:focus {
  border-color: #66768F !important;
  background-color: #0d1322;
  color: #7F92B0;
}

.theme-day .color-white {
  color: #7F92B0 !important;
}
.theme-day .color-gray-50 {
  color: #344161;
}
.theme-day .color-gray-100 {
  color: #4A576A;
}
.theme-day .color-gray-200 {
  color: #66768F;
}
.theme-day .color-gray-300 {
  color: #7F92B0;
}
.theme-day .color-gray-400 {
  color: #94A9C9;
}
.theme-day .color-gray-500 {
  color: #2E548C !important;
}
.theme-day .color-gray-600 {
  color: #7E9CC7;
}
.theme-day .color-gray-700 {
  color: #4E658A;
}
.theme-day .color-gray-800 {
  color: #C8D5E8;
}
.theme-day .color-gray-850 {
  color: #E8EDF5;
}
.theme-day .color-gray-900 {
  color: #F9FBFF;
}
.theme-day .color-gray-950 {
  color: #F9FBFF;
}
.theme-day .bg-gray-50 {
  background-color: #344161;
}
.theme-day .bg-gray-100 {
  background-color: #4A576A;
}
.theme-day .bg-gray-200 {
  background-color: #66768F;
}
.theme-day .bg-gray-300 {
  background-color: #7F92B0;
}
.theme-day .bg-gray-400 {
  background-color: #94A9C9;
}
.theme-day .bg-gray-500 {
  background-color: #2E548C;
}
.theme-day .bg-gray-600 {
  background-color: #7E9CC7;
}
.theme-day .bg-gray-700 {
  background-color: #4E658A;
}
.theme-day .bg-gray-800 {
  background-color: #C8D5E8 !important;
}
.theme-day .bg-gray-850 {
  background-color: #E8EDF5 !important;
}
.theme-day .bg-gray-900 {
  background-color: #F9FBFF !important;
}
.theme-day .bg-gray-950 {
  background-color: #F9FBFF !important;
}
.theme-day .btn.btn-tags {
  color: #05080f;
}
.theme-day .form-control {
  color: #080d18 !important;
}
.theme-day .form-control:focus {
  border-color: #C8D5E8;
  background-color: #E8EDF5;
}
.theme-day .btn.btn-border-linear {
  color: #66768F;
}

.timeread {
  background: url(../imgs/page/home/clock.svg) no-repeat left center;
  display: inline-block;
  padding: 0px 0px 0px 23px;
}

.pagination li {
  margin-right: 8px;
}
.pagination li:first-child a {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.pagination li:last-child a {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.pagination li a {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  line-height: 40px;
  background-color: #222F43;
  border: 0px;
  padding: 0px;
  text-align: center;
}
.pagination li a i {
  vertical-align: middle;
}
.pagination li a:hover {
  background-image: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
}

.theme-day .pagination li a {
  background-color: #C8D5E8;
}

.bdrd-8 {
  border-radius: 8px;
}

.post-type {
  background: url(../imgs/template/icons/post-type.svg) no-repeat center;
  height: 42px;
  width: 42px;
  position: absolute;
  top: 15px;
  right: 15px;
}
.post-type.post-audio {
  background: url(../imgs/template/icons/post-audio.svg) no-repeat center;
}
.post-type.post-image {
  background: url(../imgs/template/icons/post-image.svg) no-repeat center;
}

.border-bottom {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.item-cats {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 16px;
  margin-bottom: 24px;
}
.item-cats .cat-left {
  width: 100%;
  display: flex;
  align-items: center;
}
.item-cats .cat-left .image-cat {
  min-width: 32px;
  margin-right: 7px;
}
.item-cats .cat-left .image-cat img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  vertical-align: middle;
}
.item-cats .cat-left .info-cat {
  width: 100%;
}
.item-cats .cat-left .info-cat a:hover {
  color: #0EA5EA;
}
.item-cats .cat-right {
  position: relative;
  min-width: 100px;
  text-align: right;
}

.support {
  background: url(../imgs/page/contact/headset.svg) no-repeat left center;
  padding: 10px 30px 10px 50px;
}

.location {
  background: url(../imgs/page/contact/marker.svg) no-repeat left center;
  padding: 10px 30px 10px 50px;
}

.plane {
  background: url(../imgs/page/contact/paper-plane.svg) no-repeat left center;
  padding: 10px 30px 10px 50px;
}

.form-group {
  margin-bottom: 24px;
}

.img-bdrd-16 {
  border-radius: 16px;
  width: 100%;
}

.bdrd16 {
  border-radius: 16px !important;
}

.typewrite {
  margin-left: 0px;
}

.hover-neon {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  border: 1px solid #222F43;
  background-color: #0d1322;
}
.hover-neon:hover {
  background: linear-gradient(#0d1322, #0d1322) padding-box, linear-gradient(to right, rgb(14, 165, 234), rgb(11, 209, 209)) border-box;
  border-radius: 16px;
  border: 1px solid transparent;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  transform: translateY(-2px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.hover-neon-2 {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  border-radius: 50px;
}
.hover-neon-2:hover {
  box-shadow: rgba(11, 209, 209, 0.3) 0px 3px 20px;
}

.page-link {
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.page-link:hover {
  color: #ffffff;
  background-color: #0EA5EA;
  border-color: #0EA5EA;
  transform: translateY(-1px);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

a,
button,
img,
input,
span,
h4,
.h4 {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@keyframes slideleft {
  10% {
    opacity: 0;
    transform: scale(0);
    right: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(0);
    right: 100%;
  }
}
[data-loader=spinner] {
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: spinner 1.2s infinite ease-in-out;
  -o-animation: spinner 1.2s infinite ease-in-out;
  animation: spinner 1.2s infinite ease-in-out;
  background: url(../imgs/template/favicon.svg);
  box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    -ms-transform: perspective(120px) rotateX(0) rotateY(0);
    -o-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -ms-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -o-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(151, 119, 250, 0.8);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@keyframes jump {
  0% {
    transform: translate3d(0, 20%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.jump {
  transform-origin: 0;
  animation: jump 0.5s linear alternate infinite;
}

@-webkit-keyframes wiggle {
  0%, 100% {
    transform: scale(1.2) rotate(7deg);
  }
  50% {
    transform: scale(0.8) rotate(-7deg);
  }
}
@keyframes wiggle {
  0%, 100% {
    transform: scale(1.2) rotate(7deg);
  }
  50% {
    transform: scale(0.8) rotate(-7deg);
  }
}
/*animation*/
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slide-down {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.rotateme {
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}
@-webkit-keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
  }
}
@keyframes ripple-white {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
  }
}
/* End Shape rotate */
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes moveScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
/*TYPOGRAPHY*/
body {
  color: #94A9C9;
  background-color: #080d18;
  font-family: var(--bs-font-monospace);
  font-size: 0.9375em;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.theme-day body {
  background-color: #F9FBFF;
}

.font-heading,
.display-1,
.display-2,
.heading-lg,
.heading-sm-1 {
  font-family: var(--bs-font-monospace);
  color: #0EA5EA;
  font-weight: 700;
  line-height: 1.2;
}

.page-content {
  line-height: 1.25;
}
.page-content p {
  margin-bottom: 10px;
}
.page-content h5, .page-content .h5 {
  margin-bottom: 15px;
}

a.text-brand-3 {
  color: #0EA5EA;
}
a.text-brand-3:hover {
  color: #000000;
}

.font-xs {
  font-size: 12px;
}

.accordion {
  --bs-accordion-color: #94A9C9;
  --bs-accordion-bg: #080d18;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 0px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #94A9C9;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2394A9C9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.btn {
  border-radius: 6px;
  padding: 10px 16px;
  font-family: var(--bs-font-monospace);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
  display: inline-block;
  border: 0;
}
.btn:hover {
  color: #1CC2E7 !important;
}
.btn.btn-linear {
  background: rgb(14, 165, 234);
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
  color: #ffffff;
  transition: 0.5s;
}
.btn.btn-linear:hover {
  --x: 100%;
  color: #ffffff !important;
  transition: 0.5s;
}
.btn.btn-linear i {
  vertical-align: middle;
  display: inline-block;
}
.btn.btn-linear-small {
  background: rgb(14, 165, 234);
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
  color: #ffffff;
  padding: 6px 24px;
  border-radius: 50px;
}
.btn.btn-linear-small:hover {
  color: #66768F !important;
}
.btn.btn-linear-small i {
  vertical-align: middle;
  display: inline-block;
}
.btn.btn-search {
  height: 42px;
  width: 42px;
  background: url(../imgs/template/icons/icon-search.svg) no-repeat center;
}
.btn.btn-load-more {
  border-radius: 80px;
  padding: 18px 30px;
}
.btn.btn-load-more i {
  display: inline-block;
  vertical-align: middle;
}
.btn.btn-tag {
  border-radius: 50px;
  padding: 6px 15px;
  font-size: 0.75em;
  line-height: 16px;
  color: #ffffff;
}
.btn.btn-tag:hover {
  background-color: #0EA5EA !important;
  color: #ffffff !important;
}
.btn.btn-small {
  font-weight: 400;
  border-radius: 16px;
  padding: 3px 15px;
}
.btn.btn-radius-8 {
  border-radius: 8px;
}
.btn.btn-border-linear {
  border: 2px solid #0EA5EA;
  border-radius: 50px;
  color: #ffffff;
  padding: 5px 15px;
  font-size: 0.875em;
  line-height: 20px;
}
.btn.btn-border-linear:hover {
  color: #ffffff !important;
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200% !important;
  border: 2px solid transparent;
}
.btn.btn-link-more {
  color: #66768F;
  text-decoration: underline;
  font-size: 0.875em;
  line-height: 20px;
}
.btn.btn-tags {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 12px 20px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.btn.btn-filter {
  margin: 5px;
  padding: 12px 18px;
}
.btn.btn-filter.active {
  color: #ffffff !important;
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200% !important;
  border: 2px solid transparent;
}

.btn-tooltip {
  position: relative;
}
.btn-tooltip:after {
  bottom: 0;
  right: 34px;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 11px;
  padding: 7px 10px;
  color: #1CC2E7;
  background-color: #D5DFE4;
  content: attr(aria-label);
  line-height: 1.3;
  box-shadow: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  transform: 0;
  transition-duration: 0.2s;
}
.btn-tooltip:before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 0;
  transition-delay: 0.1s;
  border: 7px solid transparent;
  border-left-color: transparent;
  border-left-color: #D5DFE4;
  z-index: 9;
  margin-bottom: 0;
  transition: 0;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.2s;
}
.btn-tooltip:hover:after {
  opacity: 1;
  visibility: visible;
  transform: 0;
  transition-duration: 0.2s;
}
.btn-tooltip:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
  transition-duration: 0.2s;
}

.cb-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cb-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 2px solid #C9DAF5;
  border-radius: 4px;
}

.cb-container input:checked ~ .checkmark {
  border: 2px solid #1CC2E7;
}

.cb-container .text-small {
  color: #94A9C9;
}

.cb-container input:checked ~ .text-small {
  color: #080d18;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container input:checked ~ .checkmark:after {
  display: block;
}

.cb-container input:checked ~ .text-lbl {
  color: #080d18;
}

.cb-container .checkmark:after {
  left: -1px;
  top: -1px;
  width: 16px;
  height: 16px;
  background: #1CC2E7 url(../imgs/page/shop/tick.svg) no-repeat center;
}

.cb-container-2 {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cb-container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #080d18;
  border-radius: 0px;
}

.cb-container-2 input:checked ~ .checkmark {
  border: 1px solid #080d18;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cb-container-2 input:checked ~ .checkmark:after {
  display: block;
}

.cb-container-2 input:checked ~ .text-lbl {
  color: #080d18;
}

.cb-container-2 .checkmark:after {
  left: 0px;
  top: 1px;
  width: 16px;
  height: 16px;
  background: url(../imgs/page/product/checked.svg) no-repeat center;
}

/*COMPONENTS -> FORM*/
.form-control::-webkit-date-and-time-value {
  color: #66768F;
  font-size: 0.875em;
  line-height: 18px;
}

.input-name, .input-email {
  border: 0px;
  padding: 12px 12px 12px 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: transparent;
  width: 100%;
  color: #ffffff;
  background: url(../imgs/template/icons/user.svg) no-repeat left center;
}

.input-email {
  background: url(../imgs/template/icons/email.svg) no-repeat left center;
}

input {
  color: #7F92B0;
}

.box-swiper {
  position: relative;
  width: 100%;
}
.box-swiper .swiper-container {
  position: relative;
  height: 100%;
}
.box-swiper .swiper-container .item-logo {
  border: 1px solid #94A9C9;
  padding: 39px 0px 36px 0px;
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0px 45px 80px rgba(0, 0, 0, 0.04);
}
.box-swiper .swiper-container .item-logo img {
  max-width: 100%;
}
.box-swiper .swiper-container .item-logo:hover {
  border: 1px solid #222F43;
  box-shadow: 0px 47px 65px rgba(21, 28, 38, 0.1);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
}

.swiper-button-next {
  background: url(../imgs/slider/swiper/next.svg) no-repeat 0px 0px;
}
.swiper-button-next:hover {
  background: url(../imgs/slider/swiper/next-active.svg) no-repeat 0px 0px;
}

.swiper-button-prev {
  background: url(../imgs/slider/swiper/prev.svg) no-repeat 0px 0px;
}
.swiper-button-prev:hover {
  background: url(../imgs/slider/swiper/prev-active.svg) no-repeat 0px 0px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
}

.swiper-button-next {
  right: -50px;
}

.swiper-button-prev {
  left: -50px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-pagination {
  text-align: center;
  width: 100%;
}

.swiper-pagination-bullet {
  margin: 10px;
}

/*Style of the location of the div that wraps the custom pager*/
.swiper-pagination-custom {
  bottom: 40px;
  padding-left: 70px;
}

.swiper-pagination-customs {
  background: url(../imgs/slider/swiper/dot.svg) no-repeat 0px 0px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10px;
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
}

/*Customize the style representation when pager is activated*/
.swiper-pagination-customs-active,
.swiper-pagination-customs:hover {
  background: url(../imgs/slider/swiper/dot-active.svg) no-repeat 0px 0px;
  width: 12px;
  height: 12px;
  margin-bottom: -1px;
  margin-left: 3px;
  margin-right: 3px;
}

.box-button-slider {
  position: absolute;
  top: 10px;
  right: 0px;
}

.box-buttons-slider {
  min-height: 40px;
  margin-bottom: 15px;
}

.swiper-button-prev-style-1,
.swiper-button-next-style-1 {
  height: 32px;
  width: 32px;
  top: 50%;
  transition: all 0.2s;
}

.swiper-button-next-style-1 {
  left: 40px;
  transition: all 0.2s;
}
.swiper-button-next-style-1:hover {
  background: url(../imgs/slider/swiper/next-active.svg) no-repeat 0px 0px;
  transition: all 0.2s;
}

.swiper-button-prev-style-1 {
  left: 0px;
  transition: all 0.2s;
}
.swiper-button-prev-style-1:hover {
  background: url(../imgs/slider/swiper/prev-active.svg) no-repeat 0px 0px;
  transition: all 0.2s;
}

.swiper-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: -60px;
}
.swiper-buttons .swiper-button-prev-style-3 {
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  margin-left: -40px;
}
.swiper-buttons .swiper-button-next-style-3 {
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  position: relative;
  display: block;
  margin-right: -40px;
}

.swiper-group-2 .swiper-pagination {
  text-align: center;
}
.swiper-group-2 .swiper-pagination .swiper-pagination-bullet {
  background-color: #B8CDEB;
}
.swiper-group-2 .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1CC2E7;
}

.card-style-1 {
  position: relative;
  width: 100%;
}
.card-style-1:hover h6, .card-style-1:hover .h6 {
  color: #1CC2E7 !important;
}
.card-style-1:hover .card-image img {
  transform: scale(1.1);
}
.card-style-1 .card-image {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.card-style-1 .card-image img {
  width: 100%;
  border-radius: 8px;
}
.card-style-1 .card-image .link-post:hover h4, .card-style-1 .card-image .link-post:hover .h4 {
  color: #1CC2E7 !important;
}
.card-style-1 .card-image .card-info {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: url(../imgs/page/home/bg-trans.png) repeat-x left bottom;
}
.card-style-1 .card-image .card-info.card-bg-2 {
  background: url(../imgs/page/homepage3/bg-trans.png) repeat-x left bottom;
}
.card-style-1 .card-image .card-info .info-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 15px;
}
.card-style-1 .card-image .card-info .box-author img {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
}
.card-style-1 .card-image .card-info .box-author .author-info {
  display: flex;
  align-items: center;
}
.card-style-1 .card-image .card-info .box-author .author-info h6, .card-style-1 .card-image .card-info .box-author .author-info .h6 {
  line-height: 20px !important;
}

.card-blog-1 {
  padding: 20px;
  position: relative;
  width: 100%;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 30px;
  border: 1px solid #222F43;
  background-color: #0d1322;
}
.card-blog-1 .card-image {
  position: relative;
}
.card-blog-1 .card-image img {
  border-radius: 16px;
  width: 100%;
}
.card-blog-1 .card-info a:hover {
  color: #0EA5EA !important;
}
.card-blog-1 .card-info a:hover h4, .card-blog-1 .card-info a:hover .h4, .card-blog-1 .card-info a:hover h5, .card-blog-1 .card-info a:hover .h5 {
  color: #0EA5EA !important;
}
.card-blog-1:hover h4.color-white, .card-blog-1:hover .color-white.h4 {
  color: #1CC2E7 !important;
}

.card-blog-2 {
  padding: 0px;
}
.card-blog-2 .card-image img {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.card-blog-2 .card-info {
  padding: 0px 20px 20px 20px;
}

.card-style-2 {
  border-width: 1px;
  border-style: solid;
  padding: 19px;
  position: relative;
  display: flex;
  border-radius: 16px;
  align-items: center;
  margin-bottom: 30px;
}
.card-style-2 .card-image {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  min-width: 32px;
}
.card-style-2 .card-image img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.card-style-2 .card-info a:hover {
  color: #1CC2E7;
}

.card-list-posts {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
}
.card-list-posts:hover h5.color-white, .card-list-posts:hover .color-white.h5 {
  color: #1CC2E7 !important;
}
.card-list-posts .card-image {
  width: 270px;
  min-width: 270px;
  margin-right: 20px;
}
.card-list-posts .card-image img {
  width: 100%;
  border-radius: 16px;
}
.card-list-posts .card-info {
  width: 100%;
}
.card-list-posts .card-info a:hover h3, .card-list-posts .card-info a:hover .h3, .card-list-posts .card-info a:hover h4, .card-list-posts .card-info a:hover .h4, .card-list-posts .card-info a:hover h5, .card-list-posts .card-info a:hover .h5 {
  color: #1CC2E7 !important;
}
.card-list-posts .card-info .col-7 a:hover {
  color: #0EA5EA !important;
}
.card-list-posts.card-list-posts-small .card-image {
  max-width: 170px;
  min-width: 170px;
}

.card-pricing {
  border-width: 1px;
  border-style: solid;
  border-radius: 16px;
  padding: 25px;
  position: relative;
  background-position: bottom right;
}
.card-pricing:hover {
  border-color: #66768F !important;
  background: url(../imgs/page/about/shadow-1.svg) no-repeat bottom right;
}
.card-pricing .lbl-danger {
  border-radius: 16px;
  padding: 5px 15px;
  background-color: #FF756C;
  position: absolute;
  top: 30px;
  right: 20px;
}
.card-pricing .lbl-success {
  border-radius: 16px;
  padding: 5px 15px;
  background-color: #6CFFC1;
  position: absolute;
  top: 30px;
  right: 20px;
}
.card-pricing .card-pricing-top {
  padding-bottom: 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 30px;
}

.card-testimonials {
  position: relative;
  background: url(../imgs/page/about/quote.svg) no-repeat bottom right 15px;
  padding: 30px 24px 50px 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 16px;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.loader {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  color: #0EA5EA;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 3.5em;
}

.swiper-pagination {
  text-align: left;
}
.swiper-pagination .swiper-pagination-customs {
  background: #0EA5EA;
  border-radius: 50%;
}
.swiper-pagination .swiper-pagination-customs:hover {
  background: #1CC2E7;
}
.swiper-pagination .swiper-pagination-customs-active {
  background: #1CC2E7;
}
.swiper-pagination.swiper-pagination-2 {
  top: auto;
  height: auto;
  z-index: 123;
  line-height: 30px;
  text-align: center;
}

.box-newsletter {
  padding: 90px 0px;
  background: #080d18 url(../imgs/template/bg-newsletter.svg) no-repeat right 10px bottom 10px;
}
.box-newsletter .box-form-newsletter {
  position: relative;
}
.box-newsletter .box-form-newsletter .input-newsletter {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: 0px;
  height: 50px;
  padding: 5px 20px;
}
.box-newsletter .box-form-newsletter .btn-brand-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/*page loading*/
.preloader {
  background-color: #0F172A;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}
.preloader img {
  max-width: 250px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0%, 49.999%, 100% {
    transform: none;
  }
  50%, 99.999% {
    transform: rotate(90deg);
  }
}
@keyframes shift-left {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(-75%);
  }
}
@keyframes shift-right {
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(75%);
  }
}
.preloader-dots {
  --uib-size: 40px;
  --uib-speed: .9s;
  --uib-color: #0EA5EA;
  position: relative;
  height: calc(var(--uib-size) / 2);
  width: var(--uib-size);
  filter: url("#uib-jelly-ooze");
  animation: rotate calc(var(--uib-speed) * 2) linear infinite;
}
.preloader-dots::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 25%;
  width: 50%;
  height: 100%;
  background: var(--uib-color);
  border-radius: 100%;
  animation: shift-left var(--uib-speed) ease infinite;
}
.preloader-dots::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 25%;
  width: 50%;
  height: 100%;
  background: var(--uib-color);
  border-radius: 100%;
  animation: shift-right var(--uib-speed) ease infinite;
}

/*select 2 style*/
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #0EA5EA;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #0EA5EA transparent transparent transparent;
  border-width: 5px 5px 0 5px;
}

.select2-search--dropdown {
  padding: 0px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #C9DAF5;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 8px;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #D5DFE4;
  color: #0EA5EA;
}

.modal-header .btn-close {
  margin: -0.5rem 0rem -0.5rem auto;
}

.head-sidebar {
  position: relative;
}
.head-sidebar .line-bottom {
  padding-bottom: 10px;
  margin-bottom: 40px;
  background: linear-gradient(82.19deg, #0EA5EA 5.2%, #0BD1D1 100.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.head-sidebar .line-bottom::before {
  height: 3px;
  width: 96px;
  content: "";
  background-color: #0EA5EA;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.box-sidebar {
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  padding: 25px 25px 0px 25px;
  margin-bottom: 30px;
}

.list-posts .item-post {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2.5em;
}
.list-posts .item-post .image-post {
  min-width: 64px;
  max-width: 64px;
  margin-right: 15px;
}
.list-posts .item-post .image-post img {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}
.list-posts .item-post .info-post {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.list-posts .item-post .info-post a:hover h6, .list-posts .item-post .info-post a:hover .h6 {
  color: #0EA5EA !important;
}
.list-posts .item-post .info-post ul {
  padding-left: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.list-posts .item-post .info-post ul li {
  list-style: disc;
}
.list-posts .item-post:last-child {
  margin-bottom: 0px;
}
.list-posts .item-post:last-child .info-post {
  border-bottom-width: 0px;
}

.box-author-small {
  display: flex;
  align-items: center;
}
.box-author-small img {
  display: inline-block;
  margin-right: 15px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.box-author-small .author-info {
  width: 100%;
}

.list-comments {
  position: relative;
}
.list-comments .item-comment {
  border-bottom-width: 1px;
  border-style: solid;
  padding-bottom: 23px;
  margin-bottom: 23px;
}
.list-comments .item-comment:last-child {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.banner-hero {
  padding: 24px 0px 20px 0px;
  position: relative;
}

@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-animation-2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(-20px) translateX(50px);
    -moz-transform: translateY(-20px) translateX(50px);
    -ms-transform: translateY(-20px) translateX(50px);
    transform: translateY(-20px) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-20px) translateX(0px);
    -moz-transform: translateY(-20px) translateX(0px);
    -ms-transform: translateY(-20px) translateX(0px);
    transform: translateY(-20px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.shape-1 {
  -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2s linear infinite alternate;
  -o-animation: hero-thumb-animation 2s linear infinite alternate;
  animation: hero-thumb-animation 2s linear infinite alternate;
}

.shape-1-2 {
  -webkit-animation: hero-thumb-animation-2 2s linear infinite alternate;
  -moz-animation: hero-thumb-animation-2 2s linear infinite alternate;
  -o-animation: hero-thumb-animation-2 2s linear infinite alternate;
  animation: hero-thumb-animation-2 2s linear infinite alternate;
}

.shape-2 {
  -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-animation 4s linear infinite alternate;
}

.shape-3 {
  -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}

.color-linear-hover {
  color: #ffffff;
}
.color-linear-hover:hover {
  color: rgb(14, 165, 234);
}

.ultra-widescreen {
  width: 100%;
  aspect-ratio: 21/9;
  object-fit: cover;
}

.widescreen {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.square {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.portrait {
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.long-portrait {
  width: 100%;
  aspect-ratio: 9/16;
  object-fit: cover;
}

.youtube-embed {
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.header {
  padding: 10px 0px;
  margin-top: 15px;
  float: left;
  width: 100%;
  position: relative;
}
.header .main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 5px 0px;
}
.header .main-header .header-logo {
  max-width: 150px;
  width: 100%;
}
.header .main-header .header-logo a {
  display: block;
}
.header .main-header .header-logo a img {
  min-height: 37px;
}
.header .main-header .header-nav {
  display: inline-block;
  width: 65%;
  text-align: center;
}
.header .main-header .header-search {
  width: 35%;
}
.header .main-header .header-search .box-header-search {
  border-radius: 8px;
  border: 1px solid #D5DFE4;
  padding: 5px;
}
.header .main-header .header-search .box-header-search .form-search {
  display: flex;
}
.header .main-header .header-search .box-header-search .box-keysearch {
  width: 100%;
}
.header .main-header .header-search .box-header-search .box-keysearch input {
  border: 0px;
}
.header .main-header .header-search .box-header-search .box-category {
  position: relative;
  margin-right: 15px;
  min-width: 120px;
}
.header .main-header .header-search .box-header-search .box-category::before {
  content: "";
  height: 14px;
  width: 1px;
  background-color: #EBF0F3;
  position: absolute;
  top: 8px;
  right: -14px;
}
.header .main-header .header-search .box-header-search .box-category select {
  height: 100%;
  width: 100%;
  border: 0px;
  padding: 0px 20px 0px 5px;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../imgs/template/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
}
.header .main-header .header-shop {
  width: 35%;
  text-align: right;
}
.header .main-header .header-shop .icon-list {
  display: inline-block;
  padding: 0px 15px 0px 30px;
  background-repeat: no-repeat;
  background-position: 0px -1px;
  position: relative;
  font-size: 16px;
  color: #0EA5EA;
  line-height: 24px;
  cursor: pointer;
}
.header .main-header .header-shop .icon-list:hover {
  color: #000000;
}
.header .main-header .header-shop .icon-list:last-child {
  padding-right: 0px;
}
.header .main-header .header-shop .icon-list img {
  float: left;
  margin: 3px 6px 0px 0px;
}
.header .main-header .header-shop .icon-account {
  background-image: url(../imgs/template/account.svg);
}
.header .main-header .header-shop .icon-wishlist {
  background-image: url(../imgs/template/wishlist.svg);
  background-position: 0px 2px;
  padding-left: 35px;
}
.header .main-header .header-shop .icon-cart {
  background-image: url(../imgs/template/cart.svg);
  background-position: 0px 2px;
  padding-left: 35px;
}
.header .main-header .header-shop .icon-compare {
  background-image: url(../imgs/template/compare.svg);
  background-position: 0px 2px;
}
.header .main-header .header-shop .number-item {
  background-color: #1CC2E7;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: 14px;
  width: 20px;
  height: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  display: inline-block;
}
.header .main-header .header-right {
  max-width: 225px;
  width: 100%;
}
.header .main-header .header-right .block-signin {
  text-align: right;
}
.header .nav-main-menu {
  width: auto;
  padding: 0px;
  float: none;
}
.header .burger-icon {
  display: none;
}
.header .main-menu {
  display: inline-block;
  padding-top: 3px;
}
.header .main-menu li {
  float: left;
  position: relative;
  text-align: left;
}
.header .main-menu li.has-children > a::after {
  content: "\ea29";
  font-family: boxicons;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  opacity: 1;
  margin-left: 0px;
  position: relative;
  top: 2px;
}
.header .main-menu li.hr {
  padding: 0px 22px;
}
.header .main-menu li.hr span {
  background-color: #D8E6FB;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5px 0;
}
.header .main-menu li a {
  font-family: var(--bs-font-monospace);
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375em;
  line-height: 16px;
  display: block;
  padding: 13px 17px;
  text-decoration: none;
  position: relative;
}
.header .main-menu li a:hover, .header .main-menu li a.active {
  color: #1CC2E7;
}
.header .main-menu li a:hover i, .header .main-menu li a.active i {
  opacity: 1;
  transition: 0.2s;
}
.header .main-menu li a i {
  font-size: 12px;
  margin-right: 8px;
  opacity: 0.38;
  transition: 0.2s;
}
.header .main-menu li ul {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  position: absolute;
  top: calc(100% + 9px);
  left: 0px;
  z-index: 999999;
  min-width: 180px;
  border-radius: 0 0 4px 4px;
  padding: 8px 0px 8px 0;
  background: #0d1322;
  /* Gray/800 [night] */
  border: 1px solid #222F43;
  box-shadow: 0px 23.1405px 69.4215px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.header .main-menu li ul li {
  width: 100%;
}
.header .main-menu li ul li a {
  font-size: 14px;
  color: #94A9C9;
  font-size: 0.75em;
  line-height: 16px;
  position: relative;
  padding: 10px 22px 10px 38px;
  background: url(../imgs/template/icons/icon-menu.svg) no-repeat left 20px center;
  transition: 0.2s;
}
.header .main-menu li ul li a:hover {
  transition: 0.2s;
  background: url(../imgs/template/icons/icon-menu-hover.svg) no-repeat left 20px center;
}
.header .main-menu li ul li a.closer {
  padding: 8px 22px 8px 22px;
}
.header .main-menu li ul li ul {
  top: 0px;
  left: 100%;
}
.header .main-menu li:hover > ul {
  opacity: 1;
  visibility: visible;
}
.header .main-menu li:hover > ul li {
  width: 100%;
}
.header .text-link-bd-btom {
  font-family: var(--bs-font-monospace);
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375em;
  line-height: 150%;
  color: #080d18;
  display: inline-block;
  position: relative;
  padding-left: 13px;
}
.header .text-link-bd-btom:hover {
  color: #080d18;
}
.header .text-link-bd-btom::before {
  content: "";
  background: #080d18;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  animation: shadow-pulse 1s infinite;
}
.header .block-signin .btn-shadow {
  box-shadow: 0px 47px 65px rgba(21, 28, 38, 0.1);
}
.header.stick {
  padding: 10px 0px;
  margin-top: 0;
}

.switch-button {
  display: inline-block;
  padding: 0px 15px 0px 5px;
}
.switch-button .form-switch {
  padding-left: 0px;
  display: inline-block;
  vertical-align: middle;
}
.switch-button .form-switch .form-check-input {
  width: 40px;
  height: 22px;
  margin-left: 0px;
  background-image: url(../imgs/template/icons/mode.svg);
  background-position: left 2px center;
  background-color: #7F92B0;
  border-color: #7F92B0;
  background-size: 18px;
}
.switch-button .form-check-input:checked {
  background-color: #222F43;
  border-color: #222F43;
}
.switch-button .form-switch .form-check-input:checked {
  background-position: right 2px center;
  background-image: url(../imgs/template/icons/mode.svg);
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  left: 0;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #222F43;
}

.user-account {
  display: flex;
  align-items: center;
}
.user-account img {
  max-width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-account .user-name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.perfect-scroll {
  height: 100vh;
  width: 100%;
  position: relative;
}

.body-overlay-1 {
  background: transparent;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 1002;
}

.mobile-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.header-top {
  background-color: #080d18;
  color: #ffffff;
  padding: 15px 0px;
  margin-top: 30px;
}
.header-top span {
  font-family: var(--bs-font-monospace);
  font-size: 0.9375em;
  line-height: 24px;
  font-weight: bold;
}
.header-top a {
  color: #080d18;
}
.header-top .line-right {
  position: relative;
  display: inline-block;
  padding-right: 35px;
}
.header-top .line-right::before {
  content: "";
  position: absolute;
  top: 5px;
  right: 15px;
  width: 1px;
  height: 18px;
  border-right: 1px dashed #080d18;
}
.header-top.header-top-green {
  background-color: #080d18;
}
.header-top.header-top-green a {
  color: #D8E6FB;
}
.header-top.header-top-green .line-right::before {
  border-right: 1px dashed #D8E6FB;
}

@media (min-width: 1400px) {
  .header-container .container {
    max-width: 1360px;
  }
}
.header-container .main-header .header-left .header-nav {
  width: 35%;
}
.header-container .main-header .header-left .header-shop {
  width: 45%;
}

.header-bottom {
  border-top: 1px solid #D5DFE4;
  padding: 10px 0px 10px 0px;
}
.header-bottom .dropdown-toggle::after {
  display: none;
}
.header-bottom .container {
  display: flex;
}
.header-bottom .dropdown {
  padding: 0px;
}
.header-bottom .header-nav {
  width: 100%;
  padding-left: 20px;
}
.header-bottom .header-nav .main-menu {
  padding-top: 0px;
}
.header-bottom .header-nav .main-menu li a {
  padding: 9px 15px 5px 15px;
}
.header-bottom .header-nav .main-menu li.has-children > a::after {
  content: "";
  background: url(../imgs/page/homepage4/angle-down.svg) no-repeat 0px 0px;
  height: 10px;
  width: 11px;
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -4px;
}
.header-bottom .discount {
  display: inline-block;
  padding: 9px 0px 9px 42px;
  background-image: url(../imgs/page/homepage4/discount.svg);
  background-position: left center;
  background-repeat: no-repeat;
  min-width: 160px;
  max-height: 53px;
}
.header-bottom .dropdown-right {
  background-image: url(../imgs/page/homepage4/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0px 20px 0px 0px;
  display: block;
  margin-left: 0px;
}
.header-bottom .dropdown-menu {
  width: 100%;
  padding: 3px 0px;
}
.header-bottom .dropdown-menu .dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu {
  border: 1px solid #D5DFE4;
  border-radius: 4px;
}

.menu-topbar-right .dropdown-menu {
  border: 1px solid #D5DFE4;
  border-radius: 0 0 4px 4px;
}
.menu-topbar-right .dropdown-menu[data-bs-popper] {
  margin-top: 10px;
}

.dropdown-item {
  font-size: 14px;
  color: #0EA5EA;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item:hover {
  color: #0EA5EA;
  text-decoration: none;
  background-color: #D5DFE4;
}

.dropdown-language .dropdown-item img {
  margin-right: 5px;
}

.box-dropdown-cart {
  position: relative;
}

.dropdown-cart {
  display: none;
  visibility: hidden;
  position: absolute;
  right: -70px;
  top: calc(100% + 22px);
  z-index: 99;
  width: 320px;
  background-color: #fff;
  padding: 22px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 4px 4px;
  border: 1px solid #D5DFE4;
}
.dropdown-cart.dropdown-open {
  display: block;
  visibility: visible;
}

.dropdown-account {
  width: 200px;
  left: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 22px);
  z-index: 99;
  width: 220px;
  background-color: #fff;
  padding: 22px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 4px 4px;
  border: 1px solid #D5DFE4;
}
.dropdown-account.dropdown-open {
  display: block;
  visibility: visible;
}
.dropdown-account ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 20px;
}
.dropdown-account ul li:last-child {
  margin: 0;
}
.dropdown-account ul li a {
  color: #0EA5EA;
}
.dropdown-account ul li a:hover {
  color: #000000;
}

.box-breadcrumbs {
  display: inline-block;
}
.box-breadcrumbs .breadcrumb {
  margin-bottom: 0px;
}
.box-breadcrumbs .breadcrumb li {
  position: relative;
  padding: 0px 30px 0px 0px;
}
.box-breadcrumbs .breadcrumb li::before {
  content: "";
  position: absolute;
  top: 9px;
  right: 10px;
  height: 15px;
  width: 15px;
  background: url(../imgs/template/icons/right-arrow.svg) no-repeat top right;
}
.box-breadcrumbs .breadcrumb li:last-child {
  padding-right: 0px;
}
.box-breadcrumbs .breadcrumb li:last-child::before {
  display: none;
}
.box-breadcrumbs .breadcrumb li a {
  display: inline-block;
  color: #667085;
  font-size: 14px;
  line-height: 19px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-breadcrumbs .breadcrumb li a:hover {
  color: #0EA5EA;
}
.box-breadcrumbs .breadcrumb li a.home {
  background: url(../imgs/template/icons/home.svg) no-repeat left center;
  padding-left: 30px;
}
.box-breadcrumbs .breadcrumb li span {
  display: inline-block;
  padding: 5px 0;
}

.form-search {
  position: absolute;
  top: 100%;
  right: 98px;
  width: 100%;
  max-width: 350px;
  z-index: 1234;
  background-color: #05080f;
  padding: 5px 5px;
  display: none;
  border-radius: 8px;
  border: 1px solid #222F43;
}
.form-search .form-control {
  height: 46px;
  background-color: #080d18;
  border-color: #222F43;
  font-size: 14px;
  line-height: 1;
  padding: 10px 15px 10px 20px;
}
.form-search .btn-search-2 {
  position: absolute;
  top: 22px;
  right: 28px;
  width: 40px;
  height: 40px;
  background: url(../imgs/template/icons/icon-search.svg) no-repeat center;
  border: 0px;
}

.popular-keywords a:hover {
  color: #ffffff;
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 360px;
  min-height: 100vh;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999999;
}
.mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-logo {
  position: relative;
  border-bottom: 1px solid #D5DFE4;
  padding-bottom: 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  background-color: #ffffff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  border-bottom: 0;
  margin-bottom: 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 100px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 30px 30px 30px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu {
  max-width: 300px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 13px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand {
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  font-size: 12px;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 38px;
  top: 5px;
  color: #E6F0FF;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children .menu-expand i {
  font-size: 18px;
  font-weight: 300;
  opacity: 0.5;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand {
  background: #222F43;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.has-children.active > .menu-expand i::before {
  content: "\ea57";
  font-family: boxicons;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 0.9375em;
  font-family: var(--bs-font-monospace);
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #E6F0FF;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 10px 0 0 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0;
  border-bottom: none;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children .menu-expand {
  top: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li:hover a {
  padding-left: 13px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 0.875em;
  display: block;
  font-weight: 500;
  color: #E6F0FF;
  padding-left: 10px;
  position: relative;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.has-children.active {
  padding-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr {
  padding: 0 0 0 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.hr span {
  background-color: #D8E6FB;
  height: 1px;
  width: 100%;
  display: block;
  margin: 5px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #94A9C9;
  padding-left: 3px;
  transition-duration: 0.2s;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .categories-dropdown-wrap ul li a {
  padding: 5px 15px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #080d18;
  margin: 17px 0 30px 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 0.875em;
  display: block;
  font-weight: 500;
  color: #080d18;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a:hover {
  color: #080d18;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a i {
  font-size: 0.875em;
  color: #080d18;
  margin-right: 8px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 0.9375em;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #080d18;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: #080d18;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright {
  font-size: 0.75em;
  color: #E6F0FF;
}
.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .site-copyright a {
  color: #E6F0FF;
}

.burger-icon {
  position: absolute;
  width: 24px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 15px;
  right: 0px;
  z-index: 99999999;
}
.burger-icon > span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
}
.burger-icon > span.burger-icon-top {
  top: 2px;
}
.burger-icon > span.burger-icon-bottom {
  bottom: 2px;
}
.burger-icon > span.burger-icon-mid {
  top: 9px;
}
.burger-icon.burger-close {
  position: fixed;
  top: 32px;
  right: 30px;
}
.burger-icon.burger-close > span.burger-icon-top {
  display: none;
  opacity: 0;
}
.burger-icon.burger-close > span.burger-icon-mid {
  top: 8px;
  transform: rotate(45deg);
}
.burger-icon.burger-close > span.burger-icon-bottom {
  bottom: 10px;
  transform: rotate(-45deg);
}

.burger-icon > span::before,
.burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.burger-icon.burger-icon-white > span::before,
.burger-icon.burger-icon-white > span::after {
  background-color: #94A9C9;
}

.mobile-search form {
  background-color: #ffffff;
  border-radius: 8px;
  height: 44px;
  padding: 0px 15px 0px 44px;
  position: relative;
}
.mobile-search form input {
  border: 0;
  background-color: transparent;
  width: 100%;
  padding: 12px 10px;
}
.mobile-search form i {
  position: absolute;
  left: 18px;
  top: 14px;
  font-size: 18px;
  color: #222F43;
}

.mobile-social-icon h6, .mobile-social-icon .h6 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
}
.mobile-social-icon a {
  text-align: center;
  font-size: 14px;
  margin-right: 5px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  height: 30px;
  width: 30px;
  display: inline-flex;
  background: #efeaff;
  border-radius: 30px;
  line-height: 1;
  align-content: center;
  justify-content: center;
}
.mobile-social-icon a img {
  max-width: 18px;
}
.mobile-social-icon a img:hover {
  opacity: 0.8;
}
.mobile-social-icon a:hover {
  transform: translateY(-2px);
  transition-duration: 0.5s;
  margin-top: -2px;
}
.mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-account {
  margin: 20px 0 25px 0;
  padding: 30px 0 20px 0;
  border-top: thin solid #D8E6FB;
  border-bottom: thin solid #D8E6FB;
}
.mobile-account ul.mobile-menu {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
.mobile-account h6, .mobile-account .h6 {
  font-weight: 600;
  font-size: 18px;
}
.mobile-account ul li {
  padding: 8px 0;
}
.mobile-account ul li a {
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  display: inline-block;
  color: #E6F0FF;
  transition-duration: 0.2s;
}
.mobile-account ul li:hover a {
  padding-left: 3px;
  transition-duration: 0.2s;
  color: #94A9C9;
}

.topbar {
  padding: 10px 70px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #D5DFE4;
  z-index: 9999999;
  position: relative;
  background: #ffffff;
}
.topbar .container-topbar {
  display: flex;
}
.topbar .container-topbar .menu-topbar-left,
.topbar .container-topbar .menu-topbar-right,
.topbar .container-topbar .info-topbar {
  width: 33.3%;
}
.topbar .container-topbar .menu-topbar-right {
  text-align: right;
}

.top-gray-1000 {
  background-color: #080d18;
}
.top-gray-1000 .nav-small li a {
  color: #ffffff;
}
.top-gray-1000 .color-brand-3 {
  color: #ffffff !important;
}
.top-gray-1000 .dropdown-right {
  background-image: url(../imgs/page/homepage4/arrow-down.svg);
}

.dropdown-right {
  background-image: url(../imgs/template/arrow-down.svg);
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0px 15px 0px 0px;
  display: inline-block;
  margin-left: 10px;
}

.nav-small li {
  position: relative;
  display: inline-block;
}
.nav-small li a {
  display: block;
  padding: 0px 30px 0px 0px;
  color: #0EA5EA;
}
.nav-small li a:hover {
  color: #1CC2E7;
}
.nav-small li a::before {
  content: "";
  position: absolute;
  top: 5px;
  right: 15px;
  width: 1px;
  height: 13px;
  background-color: #D5DFE4;
}
.nav-small li:last-child a::before {
  display: none;
}

.sidebar-left {
  position: absolute;
  top: 0px;
  background-color: #ffffff;
  left: 0px;
  width: 50px;
  height: 100%;
  border-right: 1px solid #D5DFE4;
  z-index: 999999;
  padding-top: 0px;
  padding-bottom: 25px;
}
.sidebar-left.stick .btn.btn-open {
  height: 68px;
  background: #fff url(../imgs/template/open.svg) no-repeat center center;
}
.sidebar-left .menu-icons {
  padding: 142px 0px 0px 0px;
}
.sidebar-left .menu-icons li a {
  display: block;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.sidebar-left .menu-icons li a img {
  vertical-align: middle;
}

.menu-texts {
  position: absolute;
  top: 112px;
  left: 0px;
  background-color: #ffffff;
  min-width: 320px;
  border-right: 1px solid #D5DFE4;
  border-bottom: 1px solid #D5DFE4;
}
.menu-texts li a {
  display: block;
  padding: 0px 20px 0px 0px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #0EA5EA;
  text-decoration: none;
}
.menu-texts li a:hover, .menu-texts li a.active {
  color: #000000;
}
.menu-texts li a:hover .img-link, .menu-texts li a.active .img-link {
  position: relative;
}
.menu-texts li a:hover .img-link::before, .menu-texts li a.active .img-link::before {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #0EA5EA;
  position: absolute;
  top: 0px;
  right: 0px;
}
.menu-texts li a img {
  vertical-align: middle;
}
.menu-texts li a .img-link {
  height: 50px;
  width: 50px;
  display: inline-block;
  border-right: 1px solid #D5DFE4;
  text-align: center;
  margin-right: 15px;
}
.menu-texts li .sub-menu {
  display: none;
}
.menu-texts li:hover .sub-menu, .menu-texts li.submenu-open .sub-menu {
  display: block;
  position: absolute;
  top: 0px;
  left: 100%;
  min-width: 250px;
  height: auto;
  background-color: #ffffff;
  border-left: 1px solid #D5DFE4;
  border-right: 1px solid #D5DFE4;
  border-bottom: 1px solid #D5DFE4;
  padding-top: 8px;
  padding-bottom: 10px;
}
.menu-texts li:hover .sub-menu li a, .menu-texts li.submenu-open .sub-menu li a {
  font-weight: 400;
  color: #7F92B0;
  padding-left: 25px;
  line-height: 38px;
}
.menu-texts li:hover .sub-menu li a:hover, .menu-texts li.submenu-open .sub-menu li a:hover {
  color: #000000;
}
.menu-texts > li.has-children > a {
  padding-right: 30px;
  background: url(../imgs/template/icons/arrow-gray.svg) no-repeat right 20px center;
}
.menu-texts > li.has-children > a:hover, .menu-texts > li.has-children > a.active {
  color: #000000;
  background: url(../imgs/template/icons/arrow-gray-hover.svg) no-repeat right 20px center;
}
.menu-texts.menu-close {
  min-width: auto;
  border: 0;
}
.menu-texts.menu-close li.has-children a {
  padding: 0px;
  background-image: none;
}
.menu-texts.menu-close li a {
  padding: 0px;
}
.menu-texts.menu-close li a .text-link {
  display: none;
}
.menu-texts.menu-close li a .img-link {
  margin-right: 0px;
}

.select2-container {
  max-width: unset;
  min-width: 130px;
}

.footer .icon-socials {
  display: inline-block;
  padding-left: 28px;
  height: 32px;
  width: auto;
  transition-duration: 0.2s;
}
.footer .icon-socials:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
  color: rgb(14, 165, 234) !important;
}
.footer .icon-socials.icon-twitter {
  background: url(../imgs/template/icons/tw.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-twitter:hover {
  background: url(../imgs/template/icons/tw-hover.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-insta {
  background: url(../imgs/template/icons/insta.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-insta:hover {
  background: url(../imgs/template/icons/insta-hover.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-linked {
  background: url(../imgs/template/icons/linked.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-linked:hover {
  background: url(../imgs/template/icons/linked-hover.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-youtube {
  background: url(../imgs/template/icons/youtube.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .icon-socials.icon-youtube:hover {
  background: url(../imgs/template/icons/youtube-hover.svg) no-repeat 0px 0px;
  transition-duration: 0.2s;
}
.footer .menu-footer {
  display: inline-block;
}
.footer .menu-footer li {
  list-style: none;
  margin-bottom: 12px;
}
.footer .menu-footer li a {
  font-size: 0.9375em;
  line-height: 24px;
  text-decoration: none;
  display: block;
  padding: 0px 0px 0px 0px;
  transition-duration: 0.2s;
}
.footer .menu-footer li a:hover {
  color: #0EA5EA !important;
  transition-duration: 0.2s;
  padding-left: 3px;
}
.footer .theme-day .menu-footer li a {
  color: #2E548C;
}
.footer .theme-day .menu-footer li a:hover {
  color: #0EA5EA;
}
.footer .footer-bottom {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 50px 0px 0px 0px;
  font-size: 0.875em;
}
.footer .footer-bottom a {
  text-decoration: none;
}

#scrollUp {
  width: 54px;
  height: 54px;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  border-radius: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 999 !important;
  border: 0;
  background: url(../imgs/template/bg-top.png) no-repeat;
  transition-duration: 0.2s;
}
#scrollUp i {
  display: block;
  line-height: 59px !important;
  font-size: 20px;
  color: #fff;
}
#scrollUp:hover {
  transform: translateY(-3px);
  transition-duration: 0.2s;
}

.menu-bottom li {
  display: inline-block;
}
.menu-bottom li a {
  display: block;
  padding: 0px 15px;
}
.menu-bottom li:last-child a {
  padding-right: 0px;
}

.footer-1 {
  margin: 50px 0px 24px 0px;
  padding: 89px 128px 50px 128px;
  border-radius: 48px;
  border-width: 1px;
  border-style: solid;
}

.progressCounter {
  z-index: 12345;
  cursor: pointer;
}
.progressCounter span.progressScroll-text {
  font-size: 20px;
  color: #fff;
}

.copyright {
  color: #1CC2E7;
}
.copyright:hover {
  color: #0EA5EA;
}

/*LAYOUT -> SPACING**/
.section-padding-30 {
  padding: 30px 0;
}

.section-padding-50 {
  padding: 50px 0;
}

.section-padding-60 {
  padding: 60px 0;
}

.section-padding {
  padding: 25px 0;
}

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-260 {
  padding-top: 260px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.plr-5-percent {
  padding: 0 5%;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

.banner {
  padding-top: 50px;
}

.banner-img {
  position: relative;
  text-align: center;
}
.banner-img::before {
  content: "";
  position: absolute;
  bottom: -200px;
  left: -140px;
  height: 500px;
  width: 500px;
  background: url(../imgs/template/shadow-2.svg) no-repeat center;
  background-size: contain;
  z-index: 1;
}
.banner-img img {
  z-index: 3;
  position: relative;
  display: block;
  margin: auto;
}
.banner-img .pattern-1 {
  position: absolute;
  top: 33%;
  left: 0%;
}
.banner-img .pattern-1 img {
  animation: animationFramesOne 20s linear infinite alternate;
}
.banner-img .pattern-2 {
  position: absolute;
  top: 25%;
  right: 5%;
  animation: hero-thumb-animation 2s linear infinite alternate;
}
.banner-img .pattern-2 img {
  animation: rotateMe 10s linear infinite alternate;
}
.banner-img .pattern-3 {
  position: absolute;
  bottom: 4%;
  left: 1%;
  animation: hero-thumb-animation 2s linear infinite alternate;
}
.banner-img .pattern-4 {
  position: absolute;
  bottom: 37%;
  right: 4%;
  animation: wiggle 2s linear infinite alternate;
}

.box-subscriber {
  position: relative;
  max-width: 390px;
}
.box-subscriber .inner-subscriber {
  border-radius: 8px;
  display: flex;
  padding: 10px 10px 10px 20px;
  background: linear-gradient(#222F43, #222F43) padding-box, linear-gradient(to right, rgb(14, 165, 234), rgb(11, 209, 209)) border-box;
  border-radius: 8px;
  border: 1px solid transparent;
}
.box-subscriber .inner-subscriber:hover {
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
}
.box-subscriber .inner-subscriber form {
  width: 100%;
}
.box-subscriber .inner-subscriber .input-sybscriber {
  width: 100%;
  background-color: transparent;
  border: 0px;
}
.box-subscriber .inner-subscriber .btn-arrow-right {
  min-width: 120px;
}
.box-subscriber .inner-subscriber .btn-arrow-right i {
  display: inline-block;
  vertical-align: middle;
}

.box-topics {
  border-radius: 8px;
  padding: 24px;
  border-width: 1px;
  border-style: solid;
}

.box-author {
  display: flex;
  align-items: center;
}
.box-author img {
  display: inline-block;
  margin-right: 15px;
  height: 48px !important;
  width: 48px !important;
  border-radius: 50% !important;
}
.box-author .image-glow {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid #1A65FF;
  filter: drop-shadow(0 0 3px #00FFFB);
}
.box-author .author-info {
  width: 100%;
}

.readmore {
  position: relative;
  padding-top: 13px;
  padding-left: 24px;
}
.readmore * {
  z-index: 2;
  position: relative;
}
.readmore::before {
  content: "";
  position: absolute;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  background-color: #080d18;
  z-index: 1;
}

.cover-home1 {
  position: relative;
}
.cover-home1 .container {
  position: relative;
  z-index: 2;
}
.cover-home1::after {
  height: 800px;
  width: 500px;
  content: "";
  z-index: 1;
  background: url(../imgs/template/shadow-1.svg) no-repeat right top;
  position: absolute;
  left: 0px;
  top: 0px;
}
.cover-home1::before {
  height: 1500px;
  width: 500px;
  content: "";
  z-index: 1;
  background: url(../imgs/template/shadow-3.svg) no-repeat right bottom;
  position: absolute;
  right: 0px;
  top: 700px;
}

.pagination {
  display: inline-block;
  width: 100%;
}

.pagination li {
  margin-right: 3px;
  display: inline-block;
}

.list-tags-col-5 {
  display: flex;
  margin: 0px -10px;
  flex-wrap: wrap;
  justify-content: center;
}
.list-tags-col-5 li {
  flex: 0 0 auto;
  width: 20%;
  padding: 0px 10px;
  min-width: 150px;
  max-width: 180px;
}

.box-topics.box-topics-2 {
  padding: 0px 50px;
  position: relative;
  border: 0px;
  background-color: transparent;
}

.banner-home2 {
  padding-top: 80px;
}

.banner-1 {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
}
.banner-1 img {
  border-radius: 16px;
}

.banner-2 {
  position: absolute;
  top: 160px;
  left: 0px;
  z-index: 2;
}
.banner-2 img {
  border-radius: 16px;
}

.banner-img.no-bg::before {
  display: none;
}
.banner-img.no-bg::after {
  display: none;
}

.banner-home3 {
  padding-bottom: 100px;
  position: relative;
}
.banner-home3 .container {
  z-index: 2;
  position: relative;
}
.banner-home3::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: url(../imgs/page/homepage3/shadow-1.svg) no-repeat bottom right;
  z-index: 1;
}

.banner-home4 {
  padding-bottom: 85px;
}

.box-socials {
  display: inline-block;
  margin-top: 0px;
}
.box-socials a {
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.2s;
}
.box-socials a span {
  display: inline-block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.box-socials a span.fb {
  background-image: url(../imgs/page/homepage3/fb.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.box-socials a span.inst {
  background-image: url(../imgs/page/homepage3/inst.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.box-socials a span.snap {
  background-image: url(../imgs/page/homepage3/snap.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.box-socials a span.tw {
  background-image: url(../imgs/page/homepage3/tw.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.box-socials a:hover {
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
  transition: all 0.2s;
}

.footer .box-socials a {
  border-radius: 0px;
}

.box-banner-4 {
  display: flex;
  width: 100%;
  align-items: center;
}
.box-banner-4 .banner-image {
  max-width: 211px;
  min-width: 211px;
  margin-right: 35px;
}
.box-banner-4 .banner-image img {
  border-radius: 50%;
  width: 100%;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  border: 1px solid #0EA5EA;
}
.box-banner-4 .banner-image img:hover {
  box-shadow: rgba(11, 209, 209, 0.3) 0px 3px 20px;
}
.box-banner-4 .banner-info {
  width: 100%;
}
.box-banner-4 .box-socials a {
  height: 32px;
  width: 32px;
}
.box-banner-4 .box-socials a span {
  height: 32px;
  width: 32px;
}
.box-banner-4 .box-socials a span.fb {
  background-size: auto 14px;
}
.box-banner-4 .box-socials a span.inst {
  background-size: auto 14px;
}
.box-banner-4 .box-socials a span.snap {
  background-size: auto 14px;
}
.box-banner-4 .box-socials a span.tw {
  background-size: auto 14px;
}
.box-banner-4 .box-socials a:hover {
  background: linear-gradient(90deg, rgb(67, 102, 226), rgb(11, 209, 209) 60%, rgb(67, 102, 226)) var(--x, 0)/200%;
}

.calendar-icon {
  background: url(../imgs/template/icons/calendar.svg) no-repeat left center;
  display: inline-block;
  padding-left: 23px;
  padding-top: 2px;
}

.link-brand-1 {
  color: #0EA5EA;
}
.link-brand-1:hover {
  color: #0EA5EA;
}

.list-logos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.list-logos .item-logo {
  width: 20%;
}

.box-banner-4 {
  display: flex;
  width: 100%;
}

.box-map {
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
  display: inline-block;
}

iframe.google-map {
  width: 100%;
  height: 450px;
  border-radius: 16px;
}

.form-login {
  display: inline-block;
  max-width: 405px;
  width: 100%;
  border-radius: 16px;
  margin: auto;
  border-width: 1px;
  border-style: solid;
  padding: 30px;
}
.form-login .btn {
  width: 100%;
  height: 48px;
}

.box-form-login {
  text-align: center;
  position: relative;
}
.box-form-login::before {
  content: "";
  height: 312px;
  width: 329px;
  position: absolute;
  bottom: -50px;
  right: 0px;
  background: url(../imgs/page/login/security.svg) no-repeat bottom right;
}

.box-line {
  position: relative;
  margin: 30px auto;
  max-width: 375px;
}
.box-line::before {
  content: "";
  width: 90%;
  height: 1px;
  background-color: #222F43;
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  z-index: 1;
  margin: auto;
}
.box-line span {
  display: inline-block;
  position: relative;
  top: -4px;
  z-index: 2;
  padding: 5px 10px;
}

.box-login-gmail {
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  max-width: 405px;
  width: 100%;
  padding: 8px;
}

.btn-login-gmail {
  background: url(../imgs/page/login/gmail.svg) no-repeat left center;
  padding: 0px 0px 0px 30px;
}

.viewpass {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 30px;
  background-image: url(../imgs/page/login/view.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 65px;
  width: 50px;
  cursor: pointer;
}

.box-404 {
  max-width: 900px;
  margin: auto;
}
.box-404 .image-404 {
  max-width: 384px;
  margin-right: 40px;
}
.box-404 .info-404 {
  width: 100%;
}

.shadow-page-404 .container {
  position: relative;
  z-index: 2;
}
.shadow-page-404::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0px;
  background: url(../imgs/page/404/shadow-1.svg) no-repeat bottom 100% right 0px;
  z-index: -1;
}

.footer {
  position: relative;
  z-index: 2;
}

.box-features {
  padding: 70px 100px 20px 100px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  position: relative;
  z-index: 2;
}

.item-icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-bottom: 15px;
}
.item-icon.icon-motion {
  background: url(../imgs/page/about/motion.svg) no-repeat center;
}
.item-icon.icon-ui {
  background: url(../imgs/page/about/ui.svg) no-repeat center;
}
.item-icon.icon-branding {
  background: url(../imgs/page/about/branding.svg) no-repeat center;
}
.item-icon.icon-product {
  background: url(../imgs/page/about/product.svg) no-repeat center;
}
.item-icon.icon-key {
  background: url(../imgs/page/about/key.svg) no-repeat center;
}
.item-icon.icon-social {
  background: url(../imgs/page/about/social.svg) no-repeat center;
}

.list-checked {
  padding: 0px 0px 0px 20px;
}
.list-checked li {
  display: inline-block;
  padding: 3px 0px 3px 25px;
  margin: 0px 0px 15px 0px;
  background: url(../imgs/page/about/checked.svg) no-repeat left center;
}

.accordion-button {
  border: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.accordion-collapse {
  border-width: 0 0px;
  padding-right: 70px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-button::after {
  background-image: url(../imgs/page/about/open.svg);
  height: 54px;
  width: 54px;
  background-size: auto;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../imgs/page/about/close.svg);
  height: 54px;
  width: 54px;
  background-size: auto;
}

.accordion-item {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.accordion-item:last-child {
  border-bottom: 0px;
}

.accordion-body {
  padding: 20px 0px 40px 0px;
}

.icon-media {
  display: inline-block;
  padding: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 17.5px;
  margin: 0px 10px;
  width: 20px;
}
.icon-media.icon-fb {
  background-image: url(../imgs/page/single/fb.svg);
}
.icon-media.icon-tw {
  background-image: url(../imgs/page/single/tw.svg);
}
.icon-media.icon-tw:hover {
  background-image: url(../imgs/page/single/twitter-hover.svg);
}
.icon-media.icon-printest {
  background-image: url(../imgs/page/single/pi.svg);
}
.icon-media.icon-linkedin {
  background-image: url(../imgs/page/single/linkedin.svg);
}
.icon-media.icon-linkedin:hover {
  background-image: url(../imgs/page/single/linkedin-hover.svg);
}
.icon-media.icon-share {
  background-image: url(../imgs/page/single/share.svg);
}
.icon-media.icon-share:hover {
  background-image: url(../imgs/page/single/share-hover.svg);
}

.box-share {
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 40px;
  text-align: right;
}

.box-quote {
  padding: 120px 100px 70px 100px;
  background-image: url(../imgs/page/single/left-quote.svg);
  background-repeat: no-repeat;
  background-position: top 50px center;
  border-radius: 16px;
  text-align: center;
  margin: 50px 0px;
}

.content-detail {
  padding-bottom: 50px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .gallery-left {
    width: 47%;
  }
  .gallery-right {
    width: 53%;
  }
}
.box-tags {
  padding: 20px 0px;
}
.box-tags .btn {
  margin-bottom: 10px;
}

.box-comments {
  margin-top: 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.list-comments-single {
  padding: 40px 0px 0px 0px;
}
.list-comments-single .item-comment {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.comment-left {
  max-width: 270px;
  width: 100%;
  padding-right: 20px;
}

.item-comment-sub {
  padding-left: 40px;
}
.item-comment-sub .comment-left {
  max-width: 230px;
}

.comment-right {
  width: 100%;
}

.text-comment {
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  border-radius: 16px;
}

.cb-agree {
  float: left;
  margin: 4px 5px 0px 0px;
}

.btn-number-arts {
  position: absolute;
  top: 0px;
  left: 110%;
  min-width: 130px;
}

@media (max-width: 1550px) {
  .banner-hero.banner-2 .block-2 {
    right: -10px;
  }
  .header .main-header .header-left .header-shop .icon-list {
    padding: 0px 18px 0px 18px;
    width: 25px;
    height: 25px;
  }
  .header-shop a span {
    display: none;
  }
  .header .main-header .header-left .header-shop {
    width: 16%;
  }
}
@media (max-width: 1399.98px) {
  .block-samsung {
    padding: 25px 100px 25px 35px;
    background-size: auto 170px;
  }
  .block-drone {
    padding: 25px 120px 25px 35px;
    background-size: auto 130px;
  }
  .block-iphone {
    background-size: auto 110px;
  }
  .image-gallery .font-33 {
    font-size: 20px;
    line-height: 27px;
  }
  .image-gallery .font-32 {
    font-size: 25px;
    line-height: 32px;
  }
  .bg-metaverse {
    background-size: auto 75%;
  }
  .list-col-5 li {
    padding: 0px 4px;
  }
  .item-list .font-lg-bold {
    font-size: 16px;
    line-height: 24px;
  }
  .card-grid-style-4 .card-grid-inner .info-right {
    width: 60%;
  }
  .card-grid-style-4 .card-grid-inner .image-box {
    width: 40%;
  }
  .banner-homepage3 {
    padding-left: 65px;
  }
  .display-list .card-grid-style-3 .card-grid-inner .image-box {
    min-width: 210px;
    max-width: 210px;
  }
  .box-info-vendor .vendor-contact {
    position: relative;
    top: -40px;
    width: 100%;
  }
  .box-info-vendor {
    flex-wrap: wrap;
  }
  .box-info-vendor .info-vendor {
    width: 70%;
  }
  .border-vendor {
    margin-top: -40px;
  }
}
@media (max-width: 1199.98px) {
  .header .main-header .header-right {
    max-width: 285px;
    padding-right: 35px;
  }
  .burger-icon {
    display: inline-block;
  }
  .topbar .container-topbar .menu-topbar-right {
    text-align: center;
    width: 100%;
  }
  .list-products-5 .card-grid-style-3 {
    width: 33.333%;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 33.333%;
  }
  .header .main-header .header-left .header-shop {
    width: 35%;
    padding-right: 35px;
    min-width: 189px;
  }
  .header .main-header .header-left .header-nav {
    width: 0%;
  }
  .header .main-header .header-left .header-search {
    width: 65%;
  }
  .header .burger-icon {
    display: block;
  }
  .box-count.box-count-square .deals-countdown {
    padding: 4px 0px;
  }
  .block-iphone {
    background-size: auto 150px;
  }
  .list-9-col li {
    width: 16.66%;
  }
  .top-deals .box-top-deals .top-deals-right,
  .top-deals .box-top-deals .top-deals-left {
    width: 100%;
  }
  .top-deals .card-grid-inner .image-box {
    height: auto;
    line-height: 100%;
  }
  .bg-headphone {
    padding-bottom: 30px;
  }
  .font-xl {
    font-size: 20px;
    line-height: 26px;
  }
  .font-33 {
    font-size: 25px;
  }
  .font-32 {
    font-size: 24px;
  }
  .box-wishlist {
    max-width: 100%;
  }
  .btn.btn-arrow-2 {
    background-position: top 3px right 0px;
    padding: 0px 20px 0px 0px;
    font-size: 14px;
    line-height: 16px;
    background-size: 14px;
  }
  .info-blog .btn.btn-arrow-2 {
    padding: 0px 18px 0px 0px;
    font-size: 12px;
    line-height: 16px;
    background-size: 12px;
  }
  .gallery-image .image-left {
    min-width: 99px;
    max-width: 99px;
    padding-right: 10px;
  }
  .pl-mb-0 {
    padding-left: 0px;
  }
  .list-services li {
    width: 100%;
  }
  .footer-1 {
    padding: 89px 30px 50px 30px;
  }
  .header .main-header .header-nav {
    width: 0%;
  }
  .box-form-login::before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .footer .width-20 {
    width: 50%;
  }
  .footer .width-16 {
    width: 50%;
  }
  .list-products-5 .card-grid-style-3 {
    width: 50%;
  }
  .topbar {
    display: none;
  }
  .header .main-header .header-left .header-shop {
    padding-top: 5px;
  }
  .btn.btn-open {
    background-position: center 15px;
    height: 50px;
    position: absolute;
    background-color: transparent;
  }
  .sidebar-left {
    height: auto;
    min-height: 54px;
    top: 0px;
    border: 0;
    background-color: transparent;
  }
  .sidebar-left .menu-texts {
    border-top: 0;
  }
  .sidebar-left .menu-texts li a .img-link {
    border: 0;
  }
  .sidebar-left .menu-texts li.submenu-open .sub-menu {
    border-top: 1px solid #D5DFE4;
  }
  .banner-hero.banner-1 .banner-big {
    margin-bottom: 25px;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 50%;
  }
  .list-col-5 li {
    width: 50%;
    height: 110px;
    margin-bottom: 15px;
  }
  .bg-metaverse {
    padding: 20px 15px 0px 15px;
  }
  .bg-controller {
    background-position: center left 10px;
    background-size: auto 65%;
  }
  .banner-img-left {
    padding: 20px 20px 0px 170px;
    height: 225px;
  }
  .footer .width-25 {
    width: 100%;
  }
  .footer .width-23 {
    width: 50%;
  }
  .menu-texts {
    top: 53px;
  }
  .box-all-hurry {
    bottom: -10px;
    text-align: center;
  }
  .banner-ads::before {
    width: 200px;
  }
  .banner-ads::after {
    width: 150px;
  }
  .bg-headphone {
    background-size: auto 95%;
  }
  .menu-texts {
    display: none;
  }
  .dropdown .menu-texts {
    display: block;
  }
  .nav-tabs {
    text-align: center;
  }
  .gallery-image {
    margin-bottom: 40px;
  }
  .single-product-3 {
    margin-bottom: 20px;
  }
  .content-wishlist .item-wishlist {
    flex-wrap: wrap;
  }
  .item-wishlist .wishlist-cb {
    width: 8%;
  }
  .item-wishlist .wishlist-product {
    width: 92%;
  }
  .item-wishlist .wishlist-price {
    width: 20%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-status {
    width: 27%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-action {
    width: 35%;
    margin-top: 15px;
  }
  .item-wishlist .wishlist-remove {
    width: 18%;
    margin-top: 15px;
  }
  .head-wishlist .item-wishlist .wishlist-price,
  .head-wishlist .item-wishlist .wishlist-status,
  .head-wishlist .item-wishlist .wishlist-action,
  .head-wishlist .item-wishlist .wishlist-remove {
    display: none;
  }
  .product-wishlist .product-info {
    padding-right: 0px;
  }
  .box-bought-together {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought .product-bought {
    min-width: auto;
    max-width: none;
    margin-bottom: 30px;
    padding: 10px 0px;
  }
  .box-bought-together .price-bought {
    padding-left: 0px;
  }
  .box-banner-vendor {
    flex-wrap: wrap;
  }
  .box-banner-vendor .vendor-left {
    width: 100%;
  }
  .box-banner-vendor .vendor-right {
    width: 100%;
    max-width: 100%;
  }
  .border-vendor {
    margin-top: 30px;
  }
  .list-orders .item-orders {
    flex-wrap: wrap;
  }
  .list-orders .item-orders .info-orders {
    width: 75%;
    padding-right: 0px;
  }
  .list-orders .item-orders .image-orders {
    min-width: auto;
    max-width: none;
    width: 25%;
  }
  .list-orders .item-orders .quantity-orders {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .list-orders .item-orders .price-orders {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .box-orders .head-orders {
    display: inline-block;
  }
  .box-orders .head-orders .head-right {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
  .list-orders .item-orders .quantity-orders {
    width: 50%;
  }
  .list-orders .item-orders .price-orders {
    width: 50%;
    text-align: right;
    min-width: auto;
    max-width: none;
  }
  .nav-tabs-account {
    text-align: left;
  }
  .list-services {
    margin-bottom: 30px;
  }
  .list-services li {
    width: 50%;
  }
  .footer-bottom .box-socials {
    margin-top: 15px;
  }
}
@media (max-width: 767.98px) {
  .footer .width-20 {
    width: 100%;
  }
  .footer .width-16 {
    width: 100%;
  }
  .footer .width-23 {
    width: 100%;
  }
  .burger-icon {
    top: 17px;
  }
  .sticky-bar.stick .burger-icon {
    top: 17px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
    max-width: 140px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px;
  }
  .nav.nav-right {
    margin-top: 30px;
  }
  .list-products-5.list-products-3 .hurry-up {
    width: 100%;
  }
  .list-products-5.list-products-3 .hurry-up .card-grid-inner {
    height: auto;
  }
  .image-gallery {
    margin-bottom: 0px;
  }
  .image-gallery .image-big {
    width: 100%;
    margin-bottom: 25px;
  }
  .image-gallery .image-small {
    width: 100%;
    margin-bottom: 25px;
  }
  h1, .h1 {
    font-size: 2.625em;
    line-height: 63px;
  }
  h2, .h2 {
    font-size: 2.735em;
    line-height: 45px;
  }
  .box-newsletter {
    padding-bottom: 110px;
  }
  .box-promotions {
    height: auto;
    max-height: 515px;
  }
  .banner-hero.banner-1 .banner-big.banner-big-3 {
    background-position: center top;
  }
  .font-68 {
    font-size: 40px;
    line-height: 42px;
  }
  .list-9-col li {
    width: 25%;
  }
  .card-grid-style-2 .image-box {
    min-width: 110px;
    max-width: 110px;
    padding-right: 10px;
  }
  .nav-tabs li a h4, .nav-tabs li a .h4 {
    font-size: 20px;
  }
  .border-1-right {
    margin-right: 15px;
  }
  .border-1-right::before {
    display: none;
  }
  .dropdown .sidebar-left .menu-texts {
    display: block;
  }
  .dropdown .sidebar-left .menu-texts.menu-close {
    min-width: 320px;
  }
  .box-info-vendor .avarta .btn-buy {
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    font-size: 10px;
    line-height: 14px;
  }
  .box-info-vendor .avarta {
    max-width: 115px;
    padding: 15px 5px 10px 5px;
    top: -40px;
    width: 35%;
  }
  .box-info-vendor .info-vendor {
    width: 65%;
  }
  .box-info-vendor .info-vendor {
    padding: 0px 0px 0px 20px;
  }
  .box-info-vendor .info-vendor h4, .box-info-vendor .info-vendor .h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .box-info-vendor .info-vendor .mr-20 {
    margin-right: 0px;
  }
  .box-info-vendor {
    padding: 5px 5px;
  }
  .box-info-vendor .vendor-contact {
    top: -20px;
  }
  .location,
  .phone {
    font-size: 14px;
    line-height: 22px;
  }
  .form-tracking .box-button {
    min-width: 120px;
    max-width: 120px;
  }
  .form-tracking .btn-buy {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-orders .head-orders .head-left {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .box-orders .head-orders .head-left h5, .box-orders .head-orders .head-left .h5 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .list-notifications .item-notification .button-notification {
    min-width: 110px;
    max-width: 110px;
    text-align: center;
  }
  .list-notifications .item-notification .button-notification .btn-buy {
    padding: 7px 10px;
  }
  .list-notifications .item-notification .info-notification {
    padding-left: 10px;
    padding-right: 10px;
  }
  .list-notifications .item-notification .info-notification h5, .list-notifications .item-notification .info-notification .h5 {
    font-size: 14px;
    line-height: 18px;
  }
  .list-notifications .item-notification .info-notification .font-md {
    font-size: 12px;
    line-height: 14px;
  }
  .box-account-template {
    padding-top: 35px;
  }
  .nav-tabs-account li a {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card-list-posts .card-image {
    width: 160px;
    min-width: 160px;
  }
  .footer-1 {
    padding: 89px 20px 50px 20px;
  }
  .box-quote {
    padding-left: 20px;
    padding-right: 20px;
  }
  .list-comments-single .item-comment {
    flex-wrap: wrap;
  }
  .comment-left {
    width: 100%;
  }
  .box-share {
    height: 50px;
  }
}
@media (max-width: 575.98px) {
  .header {
    padding: 0px 0;
  }
  .header.stick {
    padding: 0px 0;
  }
  .header-container {
    padding: 5px 0 0px 0;
  }
  .header-container.stick {
    padding: 5px 0 0px 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next-style-2,
  .swiper-button-prev-style-2 {
    display: block;
  }
  .header .main-header .header-left .header-shop {
    min-width: 189px;
  }
  .header .main-header .header-left .header-search {
    display: none;
  }
  .header .container {
    max-width: 100%;
  }
  .header .main-header .header-left .header-logo {
    max-width: 100%;
  }
  .header .main-header .header-left .header-shop {
    width: 55%;
  }
  .list-products-5.list-products-3 .card-grid-style-3 {
    width: 100%;
  }
  h1, .h1 {
    font-size: 2.625em;
    line-height: 53px;
  }
  h2, .h2 {
    font-size: 2.375em;
    line-height: 35px;
  }
  h3, .h3 {
    font-size: 1.25em;
    line-height: 30px;
  }
  h4, .h4 {
    font-size: 1.125em;
    line-height: 30px;
  }
  .sidebar-left {
    top: -2px;
  }
  .box-newsletter {
    padding-bottom: 150px;
  }
  .banner-hero.banner-1 .banner-big.banner-big-2 {
    padding: 100px 40px 55px 40px;
  }
  .nav-tabs.pr-100 {
    padding-right: 0px;
  }
  .product-wishlist .product-image {
    min-width: 90px;
    max-width: 90px;
    padding: 1px;
    margin-right: 10px;
  }
  .product-wishlist .product-info h5, .product-wishlist .product-info .h5 {
    font-size: 15px;
    line-height: 20px;
  }
  .listCheckout .product-wishlist .product-info h5, .listCheckout .product-wishlist .product-info .h5 {
    font-size: 12px;
    line-height: 16px;
  }
  .mb-mobile {
    margin-bottom: 15px;
  }
  .list-services li {
    width: 100%;
  }
  .box-images-logo img {
    max-height: 16px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .banner-img::after {
    display: none;
  }
  .cover-home1::before, .cover-home1::after {
    display: none;
  }
  .header .main-header .header-right {
    padding-right: 25px;
  }
  .swiper-button-prev-style-1, .swiper-button-next-style-1 {
    display: block;
  }
}
@media (max-width: 499.98px) {
  .banner-home3 .banner-img {
    margin-top: 80px;
  }
  .box-banner-4 {
    display: block;
  }
  .box-banner-4 .banner-image {
    margin-bottom: 30px;
  }
  .box-features {
    padding: 50px 50px 20px 50px;
  }
  .form-search {
    right: 0;
  }
  .list-col-5 li {
    width: 100%;
    height: auto;
  }
  .banner-2 {
    padding: 20px 15px;
  }
  .font-33 {
    font-size: 20px;
    line-height: 23px;
  }
  .font-18 {
    font-size: 16px;
    line-height: 20px;
  }
  .bg-phone {
    background-color: #DDD3FA;
    padding: 30px 30px 30px 30px;
  }
  .nav-tabs li {
    padding: 0px 0px 0px 0px;
  }
  .nav-tabs-product li {
    padding: 5px 0px 5px 0px;
    width: 100%;
    border: 1px solid #e7e7e7;
    margin-bottom: 10px;
  }
  .nav-tabs li a {
    padding: 0px 5px;
    font-size: 12px;
    line-height: 20px;
  }
  h3, .h3 {
    font-size: 22px;
    line-height: 31px;
  }
  .font-base {
    font-size: 0.875em;
    line-height: 18px;
  }
  .box-count.box-count-square .deals-countdown .countdown-section {
    margin: 0px 4px;
  }
  .box-count.box-count-square .deals-countdown .countdown-section::before {
    right: -6px;
  }
  .box-count .deals-countdown {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card-grid-style-2 .image-box {
    min-width: 95px;
    max-width: 95px;
    padding-right: 10px;
  }
  .list-brands {
    padding: 10px 5px;
  }
  h1, .h1 {
    font-size: 2.625em;
    line-height: 1.3125em;
  }
  h2, .h2 {
    font-size: 2.375em;
    line-height: 1.1875em;
  }
  .banner-hero.banner-1 .banner-big {
    padding: 60px 20px 25px 20px;
    background-position: center bottom;
  }
  .box-newsletter {
    padding-bottom: 190px;
  }
  .block-drone,
  .block-samsung {
    padding-right: 35px;
  }
  .header-bottom .dropdown-right {
    width: 0%;
    overflow: hidden;
    padding: 0px;
  }
  .btn.btn-category {
    padding: 10px 20px 10px 35px;
    min-width: 33px;
    width: 33px;
  }
  .banner-ads::before {
    width: 150px;
  }
  .banner-ads::after {
    width: 100px;
  }
  .font-46 {
    font-size: 35px;
    line-height: 49px;
  }
  .banner-hero.banner-1 .banner-small {
    padding: 10px;
  }
  .list-9-col li {
    width: 33.33%;
  }
  .dropdown .sidebar-left {
    min-width: 58px;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
    width: 58px;
  }
  .dropdown .sidebar-left .menu-texts {
    display: block;
  }
  .dropdown .sidebar-left .menu-texts.menu-close {
    min-width: auto;
  }
  .dropdown .sidebar-left .menu-texts.menu-close li a .text-link {
    display: none;
  }
  .dropdown .sidebar-left .menu-texts.menu-close li .sub-menu {
    bottom: -6px;
    top: -6px;
  }
  .list-products-5 .card-grid-style-3 {
    width: 100%;
  }
  .box-bought-together .box-product-bought .product-bought {
    padding: 5px;
    margin-right: 30px;
  }
  .box-bought-together .box-product-bought .product-bought::before {
    left: -39px;
  }
  .gallery-image .image-left {
    min-width: 69px;
    max-width: 69px;
    width: 25%;
    padding-right: 10px;
  }
  .slider-nav-thumbnails {
    width: 75px;
  }
  .galleries {
    padding-left: 90px;
  }
  .list-orders .item-orders .image-orders {
    min-width: auto;
    max-width: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .list-orders .item-orders .info-orders {
    width: 100%;
    padding-left: 0px;
  }
  .list-orders .item-orders .info-orders h5, .list-orders .item-orders .info-orders .h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .list-orders .item-orders .quantity-orders {
    margin-top: 10px;
  }
  .list-orders .item-orders .price-orders {
    margin-top: 10px;
  }
  .list-notifications .item-notification {
    flex-wrap: wrap;
  }
  .list-notifications .item-notification .image-notification {
    min-width: auto;
    max-width: none;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .list-notifications .item-notification .info-notification {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 15px;
  }
  .nav-tabs-account li a {
    font-size: 14px;
    line-height: 20px;
  }
  .card-list-posts {
    flex-wrap: wrap;
  }
  .banner-img::before {
    display: none;
  }
  .card-list-posts .card-image {
    width: 100%;
    min-width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 449.98px) {
  .box-quantity {
    flex-wrap: wrap;
  }
  .button-buy {
    margin-top: 20px;
    width: 100%;
  }
  .item-wishlist .wishlist-price {
    width: 50%;
  }
  .item-wishlist .wishlist-status {
    width: 50%;
  }
  .item-wishlist .wishlist-action {
    width: 50%;
  }
  .item-wishlist .wishlist-remove {
    width: 50%;
  }
  .listCheckout .item-wishlist .wishlist-status {
    width: 20%;
  }
  .listCheckout .item-wishlist .wishlist-price {
    width: 30%;
  }
  .listCheckout .product-wishlist .product-image {
    min-width: 60px;
    max-width: 60px;
  }
  .listCheckout .item-wishlist {
    padding: 7px;
  }
  .box-border {
    padding: 10px;
  }
  .slider-nav-thumbnails {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
  }
  .galleries {
    padding-left: 0px;
  }
  .slider-nav-thumbnails .slick-slide {
    padding: 0px 5px;
  }
  .slider-nav-thumbnails .slick-slide .item-thumb {
    margin-top: 15px;
  }
  .slider-nav-thumbnails .slick-slide .item-thumb {
    height: auto;
    line-height: 100%;
    padding: 5px;
  }
  .product-image-slider.slick-slider .slick-track figure {
    height: auto;
    padding: 20px 0px;
  }
  .box-orders .head-orders .head-left .font-md {
    margin-bottom: 10px;
    margin-right: 0px;
    display: inline-block;
    width: 100%;
  }
  .nav-tabs-account li {
    width: 100%;
    border: 1px solid #D8E6FB;
    margin-bottom: 10px;
    text-align: center;
  }
  .nav-tabs-account li a {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 20px;
  }
  .nav-tabs-account li a {
    padding: 10px 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .pagination li a {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
  .list-posts .item-post .image-post {
    margin-right: 10px;
    min-width: 54px;
    max-width: 54px;
  }
  .list-posts .item-post .image-post img {
    height: 54px;
    width: 54px;
  }
}
@media (max-width: 349.98px) {
  .list-9-col li {
    width: 50%;
  }
  .box-quantity {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought {
    flex-wrap: wrap;
  }
  .box-bought-together .box-product-bought .product-bought {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }
  .box-bought-together .box-product-bought .product-bought::before {
    content: "";
    top: -50px;
    left: 0px;
    right: 0px;
    margin: auto;
    margin-top: 0px;
  }
  .btn.arrow-next {
    background-position: right 12px center;
    padding-right: 35px;
  }
  .arrow-back-1 {
    padding: 7px 0px 9px 15px;
    background-size: 12px;
  }
  .readmore {
    padding-left: 15px;
  }
  .box-author img {
    margin-right: 10px;
    height: 32px;
    width: 32px;
  }
}
@media (min-width: 1400px) {
  .container.wide {
    max-width: 1544px;
  }
}
/*OTHER SCREEN*/
@media screen and (max-width: 1025px) {
  .icon-list span:first-child {
    display: none;
  }
}
.page-content p {
  margin-bottom: 15px;
}